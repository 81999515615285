import React, { useEffect, useMemo, useState } from 'react';
import { Button, Input, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import EditableRow from './EditableRow';
import EditableCell from './EditableCell';
import { actions } from './actions';
import { DEFAULT_INITIAL_PAGE, DEFAULT_PAGE_SIZE } from '../../common/constant';
import { ActionTypes } from './actions';
import showMessage from '../../components/Messsage';
import { TYPE_OF_ANT_DESIGN, DATE_FORMAT } from '../../common';
import columns from './columns';
import { trim } from 'lodash-es';
import moment from 'moment';
import AddBannerModal from './AddBannerModal';

function BannerPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [searchValue, setSearchValue] = useState('');

  const banners = useSelector((state) => state.BannerReducer.banners);
  const actionType = useSelector((state) => state.BannerReducer.type);
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false);

  const queryParams = useMemo(() => {
    const params = queryString.parse(location.search);
    setSearchValue(params?.text);

    return {
      ...params,
      text: params?.text || '',
      size: Number.parseInt(params?.size) || DEFAULT_PAGE_SIZE,
      page: Number.parseInt(params?.page) || DEFAULT_INITIAL_PAGE,
    };
  }, [location.search]);
  const dataSource = banners?.content?.map((banner) => {
    return {
      key: banner?.id,
      createdAt: moment(banner?.createdAt).format(DATE_FORMAT.DDMMYY_HHMMSS),
      name: banner?.name,
      content: banner?.content,
      link: banner?.link,
      image: banner?.image,
      type: banner?.type,
    };
  });

  const onSearch = (event) => {
    const { value } = event.target;

    history.push({
      pathname: history.location.pathname,
      search: queryString.stringify({ ...queryParams, text: trim(value || searchValue) }),
    });
    dispatch(actions.getListBannerStart(queryParams));
  };

  const addBanner = () => {
    setVisibleConfirmModal(true);
  };

  const cancelConfirmModal = () => {
    setVisibleConfirmModal(false);
  };

  const handleSave = (data) => {
    console.log('data..', data);
    let formData = new FormData();
    formData.append('id', data.key);
    formData.append('name', data.name);
    formData.append('content', data.content);
    formData.append('type', data.type);
    formData.append('link', data.link);
    if (data.image && typeof data.image !== "string") {
      formData.append('image', data.image);
    }

    dispatch(
      actions.updateBannerStart(formData)
    );
  };

  const handlePageChange = (page) => {
    history.push({
      pathname: history.location.pathname,
      search: queryString.stringify({ ...queryParams, page: page - 1 }),
    });
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const confirmDeleteBanner = (data) => {
    dispatch(
      actions.deleteBannerStart({
        id: data.key
      })
    );
  }

  const newColumns = columns(confirmDeleteBanner).map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  useEffect(() => {
    dispatch(actions.getListBannerStart(queryParams));
  }, [dispatch, queryParams]);

  useEffect(() => {
    if (actionType === ActionTypes.UPDATE_BANNER_SUCCESS) {
      showMessage(TYPE_OF_ANT_DESIGN.SUCCESS, 'Update Banners Successfully!');
      dispatch(actions.resetTypeOfAction());
      dispatch(actions.getListBannerStart(queryParams));
    }
    if (actionType === ActionTypes.DELETE_BANNER_SUCCESS) {
      showMessage(TYPE_OF_ANT_DESIGN.SUCCESS, 'Delete Banners Successfully!');
      dispatch(actions.resetTypeOfAction());
      dispatch(actions.getListBannerStart(queryParams));
    }
    if (actionType === ActionTypes.CREATE_BANNER_SUCCESS) {
      showMessage(TYPE_OF_ANT_DESIGN.SUCCESS, 'Create Banners Successfully!');
      dispatch(actions.resetTypeOfAction());
      dispatch(actions.getListBannerStart(queryParams));
    }
  }, [dispatch, queryParams, actionType]);

  return (
    <div className="signal">
      <div className="search-panel">
        <Input allowClear onPressEnter={onSearch} onChange={handleSearchChange} value={searchValue} />
        <Button onClick={onSearch}>Search</Button>
        <Button style={{right: '25px', position: "absolute", backgroundColor: "#f39800", color: "white"}} onClick={addBanner}>Add Banner</Button>
      </div>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={newColumns}
        className="table"
        pagination={{
          current: queryParams.page + 1,
          pageSize: queryParams.size,
          total: banners?.totalElements || 0,
          onChange: handlePageChange,
        }}
        scroll={{x:1024}}
      />
      <AddBannerModal
        visible={visibleConfirmModal}
        cancelConfirmModal={cancelConfirmModal}
      />
    </div>
  );
}

export default BannerPage;
