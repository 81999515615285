import { ActionTypes } from './actions';

const DEFAULT_STATE = {
  signals: [],
  type: '',
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_LIST_SIGNAL_SUCCESS:
      return {
        ...state,
        signals: action.payload,
      };
    case ActionTypes.GET_LIST_SIGNAL_ERROR:
      return {
        ...state,
        signals: action.payload,
      };

    case ActionTypes.UPDATE_SIGNAL_SUCCESS:
    case ActionTypes.UPDATE_SIGNAL_ERROR:
      return {
        ...state,
        type: action.type,
      };

    case ActionTypes.GET_LIST_STATUS_SIGNAL_SUCCESS:
      return {
        ...state,
        status: action.payload,
      };
    case ActionTypes.GET_LIST_STATUS_SIGNAL_ERROR:
      return {
        ...state,
        status: action.payload,
      };

    case ActionTypes.RESET_TYPE_OF_ACTION:
      return {
        ...state,
        type: '',
      };
    default:
      return state;
  }
};
