export const ActionTypes = {
  GET_CONFIG_START: 'GET_CONFIG_START',
  GET_CONFIG_SUCCESS: 'GET_CONFIG_SUCCESS',
  GET_CONFIG_ERROR: 'GET_CONFIG_ERROR',

  UPDATE_CONFIG_START: 'UPDATE_CONFIG_START',
  UPDATE_CONFIG_SUCCESS: 'UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_ERROR: 'UPDATE_CONFIG_ERROR',

  RESET_TYPE_OF_ACTION: 'RESET_TYPE_OF_ACTION',
};

export const actions = {
  getConfigStart: (body) => ({
    type: ActionTypes.GET_CONFIG_START,
    body,
  }),
  getConfigSuccess: (payload) => ({
    type: ActionTypes.GET_CONFIG_SUCCESS,
    payload,
  }),
  getConfigError: (payload) => ({
    type: ActionTypes.GET_CONFIG_ERROR,
    payload,
  }),

  updateConfigStart: (body) => ({
    type: ActionTypes.UPDATE_CONFIG_START,
    body,
  }),
  updateConfigSuccess: (payload) => ({
    type: ActionTypes.UPDATE_CONFIG_SUCCESS,
    payload,
  }),
  updateConfigError: (payload) => ({
    type: ActionTypes.UPDATE_CONFIG_ERROR,
    payload,
  }),

  resetTypeOfAction: () => ({
    type: ActionTypes.RESET_TYPE_OF_ACTION,
  }),
};
