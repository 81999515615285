import React from 'react';
import { Modal, Typography } from 'antd';

const { Title } = Typography;

function ModalCommon({ classTitle = '', wrapClassName = '', title, content, levelTitle = 4, ...props }) {
  return (
    <Modal
      wrapClassName={`modal ${wrapClassName}`}
      maskClosable={true}
      footer={null}
      title={null}
      closable={false}
      {...props}
    >
      <div className="modal--body">
        {/* <span className="modal__icon-close" onClick={onCancel}>
          <CloseCircleFilled />
        </span> */}
        {title && (
          <Title className={`modal__title ${classTitle}`} level={levelTitle}>
            <span>{title}</span>
          </Title>
        )}
        <div className="modal__body-content">{content}</div>
      </div>
    </Modal>
  );
}

export default ModalCommon;
