import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Button, Space, InputNumber } from 'antd';
import { EditableContext } from '../EditableRow';

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <div className="editable-cell">
        {dataIndex === 'pips' && (
          <Space>
            <Form.Item
              style={{
                margin: 0,
              }}
              name={dataIndex}
              rules={[
                {
                  required: true,
                  message: `${title} is required.`,
                },
              ]}
            >
              <InputNumber
                ref={inputRef}
                onPressEnter={save}
                formatter={(value) => (value > 0 ? `+${value}` : value)}
              />
            </Form.Item>
            <span>{record.pipsUnit}</span>
          </Space>
        )}
        <Space size="middle" className="editable-cell__action">
          <Button type="link" onClick={save}>
            Save
          </Button>
          <Button type="link" onClick={toggleEdit}>
            Cancel
          </Button>
        </Space>
      </div>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;
