import React from "react";

function IconPhoto() {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="eye-open"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C1.51485 2.46819 4.97447 0 9 0C13.0255 0 16.4851 2.46819 18 6C16.4851 9.53181 13.0255 12 9 12C4.97447 12 1.51485 9.53181 0 6ZM13 6C13 8.20914 11.2091 10 9 10C6.79086 10 5 8.20914 5 6C5 3.79086 6.79086 2 9 2C9.63891 2 10.2428 2.14979 10.7786 2.41618C10.305 2.78193 10 3.35535 10 4C10 5.10457 10.8954 6 12 6C12.3608 6 12.6993 5.90447 12.9915 5.73732C12.9971 5.82415 13 5.91174 13 6Z"
        fill="#B2B2B2"
      />
      <path
        d="M11 6C11 7.10457 10.1046 8 9 8C7.89543 8 7 7.10457 7 6C7 4.89543 7.89543 4 9 4C10.1046 4 11 4.89543 11 6Z"
        fill="#B2B2B2"
      />
    </svg>
  );
}

export default IconPhoto;
