import React from 'react';

function IconSearch() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.33334 0.333008C9.64705 0.333008 12.3333 3.0193 12.3333 6.33301C12.3333 7.74953 11.8425 9.0514 11.0215 10.0778L13.4714 12.5283C13.7318 12.7886 13.7318 13.2107 13.4714 13.4711C13.2311 13.7114 12.8529 13.7299 12.5914 13.5265L12.5286 13.4711L10.0781 11.0212C9.05173 11.8421 7.74986 12.333 6.33334 12.333C3.01963 12.333 0.333336 9.64672 0.333336 6.33301C0.333336 3.0193 3.01963 0.333008 6.33334 0.333008ZM6.33334 1.66634C3.75601 1.66634 1.66667 3.75568 1.66667 6.33301C1.66667 8.91034 3.75601 10.9997 6.33334 10.9997C8.91066 10.9997 11 8.91034 11 6.33301C11 3.75568 8.91066 1.66634 6.33334 1.66634Z"
        fill="black"
        fillOpacity="0.5"
      />
    </svg>
  );
}

export default IconSearch;
