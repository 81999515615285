export const ActionTypes = {
  GET_LIST_REPORT_SALE_START: 'GET_LIST_REPORT_SALE_START',
  GET_LIST_REPORT_SALE_SUCCESS: 'GET_LIST_REPORT_SALE_SUCCESS',
  GET_LIST_REPORT_SALE_ERROR: 'GET_LIST_REPORT_SALE_ERROR',

  GET_LIST_REPORT_USER_START: 'GET_LIST_REPORT_USER_START',
  GET_LIST_REPORT_USER_SUCCESS: 'GET_LIST_REPORT_USER_SUCCESS',
  GET_LIST_REPORT_USER_ERROR: 'GET_LIST_REPORT_USER_ERROR',

};

export const actions = {
  getListReportSaleStart: (params) => ({
    type: ActionTypes.GET_LIST_REPORT_SALE_START,
    params,
  }),
  getListReportSaleSuccess: (payload) => ({
    type: ActionTypes.GET_LIST_REPORT_SALE_SUCCESS,
    payload,
  }),
  getListReportSaleError: (payload) => ({
    type: ActionTypes.GET_LIST_REPORT_SALE_ERROR,
    payload,
  }),

  getListReportUserStart: (params) => ({
    type: ActionTypes.GET_LIST_REPORT_USER_START,
    params,
  }),
  getListReportUserSuccess: (payload) => ({
    type: ActionTypes.GET_LIST_REPORT_USER_SUCCESS,
    payload,
  }),
  getListReportUserError: (payload) => ({
    type: ActionTypes.GET_LIST_REPORT_USER_ERROR,
    payload,
  }),
};
