import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, Popover } from 'antd';
import { ICON } from '../../common';
import { actions as actionsLogin } from '../../pages/Login/actions';
import { useDispatch } from 'react-redux';
import LogoSider from '../../resources/images/avatar.png';
import { useWindowSize } from '../../utils';

const { Header } = Layout;

function HeaderCommon() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const isMobile = width <= 576;
  const [visiblePopoverMenu, setVisiblePopoverMenu] = useState(false);

  const handleVisibleChange = (visible) => {
    setVisiblePopoverMenu(visible);
  };

  const handleLogout = () => {
    localStorage.removeItem('persist:root');
    dispatch(actionsLogin.postLogout());
  };

  const renderPopoverMenu = () => {
    return (
      <div className="layout__popover-menu">
        <div onClick={handleLogout} className="item">
          {ICON.Exit} {t('common.logout')}
        </div>
      </div>
    );
  };

  return (
    <Header className={`layout__header ${isMobile && 'w-header'}`}>
      <div className="content-header">
        <div id="popover-content" className="content-header__item">
          <Popover
            visible={visiblePopoverMenu}
            content={renderPopoverMenu()}
            trigger="click"
            placement="bottomRight"
            getPopupContainer={() => document.querySelector('#popover-content')}
            onVisibleChange={handleVisibleChange}
          >
            <img src={LogoSider} alt="logo" />
          </Popover>
        </div>
      </div>
    </Header>
  );
}
export default memo(HeaderCommon);
