import React, { useEffect, useMemo, useState } from 'react';
import { Button, Table, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { renderCollum } from './columns';
import { actions, ActionTypes } from '../actions';
import { DEFAULT_INITIAL_PAGE, DEFAULT_PAGE_SIZE } from '../../../common/constant';
import UpgradeModal from '../Modal/UpgradeModal';
import { trim } from 'lodash-es';
import moment from 'moment';
import { DATE_FORMAT, TYPE_OF_ANT_DESIGN, USER_TAB } from '../../../common';
import EditableRow from '../EditableRow';
import EditableCell from '../EditableCell';
import showMessage from '../../../components/Messsage';

function SingleUpgrade({ curTab }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const users = useSelector((state) => state.UsersReducer.users);
  const updateUserStatus = useSelector((state) => state.UsersReducer.type);

  const [searchValue, setSearchValue] = useState('');

  const [user, setUser] = useState();
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false);

  const queryParams = useMemo(() => {
    const params = queryString.parse(location.search);
    setSearchValue(params?.text);

    return {
      ...params,
      size: Number.parseInt(params?.size) || DEFAULT_PAGE_SIZE,
      page: Number.parseInt(params?.page) || DEFAULT_INITIAL_PAGE,
    };
  }, [location.search]);

  const dataSource = users?.content?.map((item, index) => {
    return {
      key: index,
      username: item?.username,
      email: item?.email,
      currentPackage: item?.currentPackage,
      action: item?.id,
      status: item?.status,
      amount: item?.amount,
      nameOfSale: item?.nameOfSale,
      createdAt: item?.createdAt && item?.createdAt !== 0 ? moment.unix(item?.createdAt).format(DATE_FORMAT.DDMMYY_HHMMSS) : '--',
      upgradedAt: item?.upgradedAt && item?.upgradedAt !== 0 ? moment.unix(item?.upgradedAt).format(DATE_FORMAT.DDMMYY_HHMMSS) : '--',
      upgradedTo: item?.upgradedTo && item?.upgradedTo !== 0  ? moment.unix(item?.upgradedTo).format(DATE_FORMAT.DDMMYY_HHMMSS) : '--',
    };
  });

  const toggleFormModal = (user) => {
    setUser(user);
    setVisibleConfirmModal(true);
  };

  const handleSave = (data) => {
    const { email, amount, nameOfSale } = data;

    dispatch(
      actions.updateUserStart({
        email: email,
        amount: amount,
        nameOfSale: nameOfSale,
      })
    );
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const newColumns = renderCollum(toggleFormModal)?.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  const onSearch = (event) => {
    const { value } = event.target;
    history.push({
      pathname: history.location.pathname,
      search: queryString.stringify({ ...queryParams, text: trim(value || searchValue) }),
    });
    dispatch(actions.getListUserStart(queryParams));
  };

  const handlePageChange = (page) => {
    history.push({
      pathname: history.location.pathname,
      search: queryString.stringify({ ...queryParams, page: page - 1 }),
    });
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  useEffect(() => {
    if (curTab === USER_TAB.SINGLE) {
      dispatch(actions.getListUserStart(queryParams));
    }
  }, [dispatch, queryParams, curTab]);

  useEffect(() => {
    if (updateUserStatus === ActionTypes.UPDATE_USER_SUCCESS) {
      showMessage(TYPE_OF_ANT_DESIGN.SUCCESS, 'Update Users Successfully!');
      dispatch(actions.resetTypeOfAction());
      dispatch(actions.getListUserStart(queryParams));
    }
  }, [dispatch, queryParams, updateUserStatus]);

  const cancelConfirmModal = () => {
    setVisibleConfirmModal(false);
  };

  return (
    <div className="single_tab">
      <div className="search-panel">
        <Input allowClear onPressEnter={onSearch} onChange={handleSearchChange} value={searchValue} />
        <Button onClick={onSearch}>Search</Button>
      </div>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={newColumns}
        className="table"
        pagination={{
          current: queryParams.page + 1,
          pageSize: queryParams.size,
          total: users?.totalElements || 0,
          onChange: handlePageChange,
        }}
        scroll={{x:1024}}
      />
      <UpgradeModal
        visible={visibleConfirmModal}
        user={user}
        paramsSearch={searchValue}
        cancelConfirmModal={cancelConfirmModal}
      />
    </div>
  );
}
export default SingleUpgrade;
