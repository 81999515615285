import { ActionTypes } from './actions';

const DEFAULT_STATE = {
  users: {
    content: [],
    page: 0,
    size: 10,
    totalElements: 0,
  },
  listPackage: [],
  bundleUsers: [],
  type: '',
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_LIST_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };
    case ActionTypes.GET_LIST_USERS_ERROR:
      return {
        ...state,
        users: action.payload,
      };

    case ActionTypes.GET_LIST_PACKAGE_START:
      return {
        ...state,
      };
    case ActionTypes.GET_LIST_PACKAGE_SUCCESS:
      return {
        ...state,
        listPackage: action.payload,
      };
    case ActionTypes.UPGRADE_BUNDLE_USER_SUCCESS:
      return {
        ...state,
        bundleUsers: action.payload,
      };
    case ActionTypes.UPGRADE_BUNDLE_USER_ERROR:
      return {
        ...state,
        bundleUsers: action.payload,
      };
    case ActionTypes.UPDATE_USER_SUCCESS:
    case ActionTypes.UPDATE_USER_ERROR:
      return {
        ...state,
        type: action.type,
      };
    default:
      return state;
  }
};
