import React from 'react';

function IconPoint() {
  return (
    <svg
      width="20"
      className="icon-point"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill="url(#paint0_linear)"
      />
      <g opacity="0.53">
        <path
          opacity="0.53"
          d="M18.4113 10.5889C18.1048 14.9679 14.4597 18.4276 9.99998 18.4276C5.54031 18.4276 1.89515 14.9679 1.58869 10.5889H0.0161133C0.322565 15.8389 4.6774 20.0001 9.99998 20.0001C15.3226 20.0001 19.6774 15.8389 19.9839 10.5889H18.4113Z"
          fill="#F39800"
        />
      </g>
      <path
        d="M9.99995 17.8229C14.3202 17.8229 17.8225 14.3206 17.8225 10.0003C17.8225 5.68002 14.3202 2.17773 9.99995 2.17773C5.67966 2.17773 2.17737 5.68002 2.17737 10.0003C2.17737 14.3206 5.67966 17.8229 9.99995 17.8229Z"
        fill="#D17700"
      />
      <path
        d="M10.3226 17.5C14.4648 17.5 17.8226 14.1421 17.8226 10C17.8226 5.85786 14.4648 2.5 10.3226 2.5C6.1805 2.5 2.82263 5.85786 2.82263 10C2.82263 14.1421 6.1805 17.5 10.3226 17.5Z"
        fill="url(#paint1_linear)"
      />
      <g opacity="0.8">
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M9.79027 1.9357C9.72576 1.9357 9.67737 1.88732 9.67737 1.8228V0.387317C9.67737 0.322801 9.72576 0.274414 9.79027 0.274414C9.85479 0.274414 9.90318 0.322801 9.90318 0.387317V1.8228C9.90318 1.88732 9.85479 1.9357 9.79027 1.9357Z"
            fill="url(#paint2_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M8.3952 2.09711C8.33068 2.10517 8.27423 2.06485 8.26616 2.0084L8.01616 0.589044C8.0081 0.524528 8.04842 0.468076 8.10487 0.460012C8.16939 0.451947 8.22584 0.49227 8.23391 0.548722L8.48391 1.96808C8.49197 2.02453 8.45165 2.08098 8.3952 2.09711Z"
            fill="url(#paint3_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M7.04842 2.49256C6.99197 2.51676 6.92746 2.4845 6.90326 2.42805L6.41133 1.07321C6.38713 1.01676 6.41939 0.952243 6.47584 0.928049C6.53229 0.903856 6.59681 0.936114 6.621 0.992565L7.11294 2.3474C7.13713 2.40385 7.10488 2.47643 7.04842 2.49256Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M5.7823 3.12087C5.72585 3.15313 5.66134 3.137 5.62908 3.08055L4.91134 1.83861C4.87908 1.78216 4.89521 1.71764 4.95166 1.68539C5.00811 1.65313 5.07263 1.66926 5.10489 1.72571L5.82262 2.96764C5.85488 3.02409 5.83875 3.08861 5.7823 3.12087Z"
            fill="url(#paint5_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M4.65334 3.95944C4.60495 3.99977 4.53237 3.9917 4.49205 3.94332L3.57269 2.83848C3.53237 2.79009 3.54043 2.71751 3.58882 2.67718C3.63721 2.63686 3.70979 2.64493 3.75011 2.69331L4.67753 3.79815C4.70979 3.84654 4.70172 3.91912 4.65334 3.95944Z"
            fill="url(#paint6_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M3.68551 4.97586C3.64519 5.02424 3.57261 5.03231 3.52422 4.99198L2.42744 4.06457C2.37906 4.02424 2.37099 3.95166 2.41132 3.90327C2.45164 3.85489 2.52422 3.84682 2.57261 3.88715L3.67744 4.81457C3.71777 4.85489 3.72583 4.92747 3.68551 4.97586Z"
            fill="url(#paint7_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M2.91152 6.15303C2.87926 6.20948 2.81475 6.22561 2.75829 6.19335L1.51636 5.47561C1.45991 5.44336 1.44378 5.37884 1.47604 5.32239C1.50829 5.26594 1.57281 5.24981 1.62926 5.28207L2.8712 5.99981C2.92765 6.024 2.94378 6.09658 2.91152 6.15303Z"
            fill="url(#paint8_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M2.34681 7.43599C2.32261 7.49244 2.2581 7.5247 2.20164 7.50051L0.85487 7.01663C0.798418 6.99244 0.76616 6.92792 0.790354 6.87147C0.814547 6.81502 0.879063 6.78276 0.935515 6.80696L2.29035 7.29889C2.33874 7.31502 2.371 7.37954 2.34681 7.43599Z"
            fill="url(#paint9_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M2.0242 8.80642C2.01613 8.87093 1.95162 8.91125 1.89517 8.89513L0.475811 8.64513C0.411295 8.63706 0.370972 8.57255 0.387101 8.51609C0.395166 8.45158 0.459682 8.41126 0.516134 8.42738L1.93549 8.67738C1.99194 8.68545 2.03226 8.7419 2.0242 8.80642Z"
            fill="url(#paint10_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M1.93558 10.2096C1.93558 10.2741 1.8872 10.3225 1.82268 10.3225H0.387195C0.322679 10.3225 0.274292 10.2741 0.274292 10.2096C0.274292 10.1451 0.322679 10.0967 0.387195 10.0967H1.82268C1.8872 10.0967 1.93558 10.1451 1.93558 10.2096Z"
            fill="url(#paint11_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M2.09674 11.6054C2.10481 11.6699 2.06448 11.7263 2.00803 11.7344L0.588678 11.9844C0.524162 11.9925 0.46771 11.9521 0.459646 11.8957C0.451581 11.8312 0.491904 11.7747 0.548355 11.7667L1.96771 11.5167C2.02416 11.5086 2.08061 11.5489 2.09674 11.6054Z"
            fill="url(#paint12_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M2.49195 12.9516C2.51615 13.008 2.48389 13.0725 2.42744 13.0967L1.0726 13.5886C1.01615 13.6128 0.951632 13.5806 0.927439 13.5241C0.903245 13.4677 0.935503 13.4032 0.991955 13.379L2.34679 12.887C2.40325 12.8628 2.47583 12.8951 2.49195 12.9516Z"
            fill="url(#paint13_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M3.12111 14.2179C3.15337 14.2743 3.13724 14.3389 3.08079 14.3711L1.83886 15.0889C1.7824 15.1211 1.71789 15.105 1.68563 15.0485C1.65337 14.9921 1.6695 14.9276 1.72595 14.8953L2.96789 14.1776C3.02434 14.1453 3.08885 14.1614 3.12111 14.2179Z"
            fill="url(#paint14_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M3.95969 15.3466C4.00001 15.395 3.99195 15.4676 3.94356 15.5079L2.83872 16.4353C2.79033 16.4757 2.71775 16.4676 2.67743 16.4192C2.63711 16.3708 2.64517 16.2982 2.69356 16.2579L3.7984 15.3305C3.84679 15.2902 3.91937 15.2982 3.95969 15.3466Z"
            fill="url(#paint15_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M4.97586 16.3149C5.02424 16.3552 5.03231 16.4278 4.99198 16.4762L4.06457 17.581C4.02424 17.6294 3.95166 17.6375 3.90327 17.5971C3.85489 17.5568 3.84682 17.4842 3.88715 17.4359L4.81457 16.331C4.85489 16.2826 4.92747 16.2746 4.97586 16.3149Z"
            fill="url(#paint16_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M6.15327 17.0887C6.20973 17.121 6.22585 17.1855 6.1936 17.2419L5.47586 18.4839C5.4436 18.5403 5.37908 18.5565 5.32263 18.5242C5.26618 18.4919 5.25005 18.4274 5.28231 18.371L6.00005 17.129C6.02424 17.0726 6.09682 17.0564 6.15327 17.0887Z"
            fill="url(#paint17_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M7.43562 17.6537C7.49207 17.6779 7.52433 17.7424 7.50013 17.7989L7.01626 19.1457C6.99207 19.2021 6.92756 19.2344 6.8711 19.2102C6.81465 19.186 6.7824 19.1215 6.80659 19.065L7.29852 17.7102C7.31465 17.6618 7.37917 17.6295 7.43562 17.6537Z"
            fill="url(#paint18_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M8.80654 17.9756C8.87105 17.9837 8.91138 18.0482 8.89525 18.1047L8.64525 19.524C8.63718 19.5885 8.57267 19.6289 8.51622 19.6127C8.4517 19.6047 8.41138 19.5402 8.42751 19.4837L8.67751 18.0643C8.68557 18.0079 8.74202 17.9676 8.80654 17.9756Z"
            fill="url(#paint19_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M10.2097 18.0645C10.2742 18.0645 10.3226 18.1128 10.3226 18.1774V19.6128C10.3226 19.6774 10.2742 19.7257 10.2097 19.7257C10.1452 19.7257 10.0968 19.6774 10.0968 19.6128V18.1774C10.0968 18.1128 10.1452 18.0645 10.2097 18.0645Z"
            fill="url(#paint20_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M11.605 17.9034C11.6695 17.8953 11.726 17.9356 11.734 17.9921L11.984 19.4114C11.9921 19.476 11.9518 19.5324 11.8953 19.5405C11.8308 19.5485 11.7744 19.5082 11.7663 19.4518L11.5163 18.0324C11.5082 17.976 11.5485 17.9195 11.605 17.9034Z"
            fill="url(#paint21_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M12.9517 17.5081C13.0081 17.4839 13.0726 17.5162 13.0968 17.5726L13.5888 18.9275C13.613 18.9839 13.5807 19.0484 13.5243 19.0726C13.4678 19.0968 13.4033 19.0646 13.3791 19.0081L12.8872 17.6533C12.863 17.5968 12.8952 17.5243 12.9517 17.5081Z"
            fill="url(#paint22_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M14.2179 16.8787C14.2743 16.8465 14.3389 16.8626 14.3711 16.9191L15.0889 18.161C15.1211 18.2175 15.105 18.282 15.0485 18.3142C14.9921 18.3465 14.9276 18.3304 14.8953 18.2739L14.1776 17.032C14.1453 16.9755 14.1614 16.911 14.2179 16.8787Z"
            fill="url(#paint23_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M15.3469 16.0405C15.3953 16.0001 15.4678 16.0082 15.5082 16.0566L16.4356 17.1614C16.4759 17.2098 16.4679 17.2824 16.4195 17.3227C16.3711 17.363 16.2985 17.355 16.2582 17.3066L15.3307 16.2018C15.2904 16.1534 15.2985 16.0808 15.3469 16.0405Z"
            fill="url(#paint24_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M16.3146 15.0244C16.355 14.976 16.4275 14.9679 16.4759 15.0082L17.5808 15.9357C17.6291 15.976 17.6372 16.0486 17.5969 16.097C17.5566 16.1453 17.484 16.1534 17.4356 16.1131L16.3308 15.1857C16.2824 15.1453 16.2743 15.0728 16.3146 15.0244Z"
            fill="url(#paint25_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M17.0888 13.8468C17.1211 13.7904 17.1856 13.7742 17.2421 13.8065L18.484 14.5242C18.5404 14.5565 18.5566 14.621 18.5243 14.6775C18.492 14.7339 18.4275 14.75 18.3711 14.7178L17.1292 14C17.0727 13.9758 17.0566 13.9033 17.0888 13.8468Z"
            fill="url(#paint26_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M17.6534 12.5648C17.6776 12.5084 17.7421 12.4761 17.7986 12.5003L19.1534 12.9923C19.2098 13.0164 19.2421 13.081 19.2179 13.1374C19.1937 13.1939 19.1292 13.2261 19.0727 13.2019L17.7179 12.71C17.6615 12.6858 17.6292 12.6213 17.6534 12.5648Z"
            fill="url(#paint27_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M17.9759 11.1938C17.9839 11.1293 18.0485 11.089 18.1049 11.1051L19.5243 11.3551C19.5888 11.3632 19.6291 11.4277 19.613 11.4841C19.6049 11.5487 19.5404 11.589 19.4839 11.5729L18.0646 11.3229C18.0081 11.3148 17.9678 11.2583 17.9759 11.1938Z"
            fill="url(#paint28_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M18.0646 9.79064C18.0646 9.72612 18.113 9.67773 18.1775 9.67773H19.613C19.6775 9.67773 19.7259 9.72612 19.7259 9.79064C19.7259 9.85515 19.6775 9.90354 19.613 9.90354H18.1775C18.113 9.90354 18.0646 9.85515 18.0646 9.79064Z"
            fill="url(#paint29_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M17.9034 8.39568C17.8953 8.33116 17.9356 8.27471 17.9921 8.26665L19.4114 8.01665C19.476 8.00859 19.5324 8.04891 19.5405 8.10536C19.5485 8.16988 19.5082 8.22633 19.4518 8.23439L18.0324 8.48439C17.976 8.49245 17.9195 8.45213 17.9034 8.39568Z"
            fill="url(#paint30_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M17.5082 7.04854C17.4841 6.99209 17.5163 6.92757 17.5728 6.90338L18.9276 6.41145C18.9841 6.38725 19.0486 6.41951 19.0728 6.47596C19.097 6.53241 19.0647 6.59693 19.0083 6.62112L17.6534 7.11305C17.597 7.13725 17.5244 7.10499 17.5082 7.04854Z"
            fill="url(#paint31_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M16.8792 5.78291C16.847 5.72646 16.8631 5.66195 16.9196 5.62969L18.1615 4.91195C18.2179 4.87969 18.2825 4.89582 18.3147 4.95227C18.347 5.00872 18.3309 5.07324 18.2744 5.1055L17.0325 5.82324C16.976 5.85549 16.9115 5.83936 16.8792 5.78291Z"
            fill="url(#paint32_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M16.0403 4.65359C16 4.6052 16.0081 4.53262 16.0565 4.4923L17.1613 3.56488C17.2097 3.52456 17.2823 3.53262 17.3226 3.58101C17.3629 3.6294 17.3549 3.70198 17.3065 3.7423L16.2016 4.66972C16.1532 4.71004 16.0807 4.70198 16.0403 4.65359Z"
            fill="url(#paint33_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M15.0242 3.68601C14.9759 3.64568 14.9678 3.5731 15.0081 3.52472L15.9355 2.41988C15.9759 2.37149 16.0484 2.36342 16.0968 2.40375C16.1452 2.44407 16.1533 2.51665 16.113 2.56504L15.1855 3.66988C15.1452 3.71826 15.0726 3.72633 15.0242 3.68601Z"
            fill="url(#paint34_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M13.8468 2.91189C13.7904 2.87963 13.7742 2.81511 13.8065 2.75866L14.5242 1.51672C14.5565 1.46027 14.621 1.44414 14.6775 1.4764C14.7339 1.50866 14.75 1.57318 14.7178 1.62963L14 2.87156C13.9758 2.92801 13.9033 2.94414 13.8468 2.91189Z"
            fill="url(#paint35_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M12.5646 2.34706C12.5081 2.32286 12.4759 2.25835 12.5001 2.2019L12.992 0.847057C13.0162 0.790606 13.0807 0.758348 13.1372 0.782541C13.1936 0.806735 13.2259 0.871251 13.2017 0.927703L12.7097 2.28254C12.6856 2.33899 12.621 2.37125 12.5646 2.34706Z"
            fill="url(#paint36_linear)"
          />
        </g>
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M11.1937 2.02444C11.1292 2.01638 11.0889 1.95186 11.105 1.89541L11.355 0.476055C11.3631 0.411539 11.4276 0.371217 11.484 0.387346C11.5485 0.39541 11.5889 0.459926 11.5727 0.516378L11.3227 1.93573C11.3147 1.99218 11.2582 2.03251 11.1937 2.02444Z"
            fill="url(#paint37_linear)"
          />
        </g>
      </g>
      <path
        d="M13.7419 5.62135C13.6693 5.68587 13.5725 5.76651 13.4677 5.8391C12.8628 6.27458 11.758 6.76651 10.4032 6.02458C10.3306 5.98426 10.258 5.94393 10.1854 5.89555C10.1854 5.89555 11.5161 3.8391 13.7096 5.21813C13.8548 5.3149 13.8709 5.51651 13.7419 5.62135Z"
        fill="#4E901E"
      />
      <path
        d="M13.4677 5.84706C12.8629 6.28255 11.758 6.77449 10.4032 6.03255C10.9677 5.53255 12.0322 4.94383 13.4677 5.84706Z"
        fill="#5EAC24"
      />
      <path
        d="M12.5805 3.88683C12.5563 3.97554 12.5241 4.07232 12.4837 4.18522C12.2418 4.83845 11.6531 5.82232 10.266 6.04006C10.1047 6.06425 9.94343 6.08038 9.75794 6.08038C9.75794 6.08038 9.73375 3.62877 12.3305 3.58038C12.5079 3.57232 12.6289 3.72554 12.5805 3.88683Z"
        fill="#6DC82A"
      />
      <path
        d="M12.492 4.18555C12.2501 4.83877 11.6614 5.82264 10.2743 6.04039C10.4517 5.34684 10.9678 4.32264 12.492 4.18555Z"
        fill="#7ED63E"
      />
      <path
        d="M13.5967 12.331C12.8871 13.5568 11.6612 14.2988 10.3548 14.4439C9.48383 14.5407 8.57254 14.3713 7.75802 13.8955C5.7177 12.7181 5.01608 10.1052 6.1935 8.05683C7.06447 6.5407 8.72576 5.76651 10.3629 5.94393C10.9354 6.00844 11.5 6.18586 12.0322 6.49231C14.0806 7.6778 14.7741 10.2907 13.5967 12.331Z"
        fill="#EF8800"
      />
      <path
        d="M7.7015 6.49175C8.23376 6.1853 8.79827 6.00788 9.37085 5.94336C10.6773 6.08852 11.9031 6.83046 12.6128 8.05626C13.7902 10.0966 13.0967 12.7095 11.0483 13.895C10.516 14.2014 9.9515 14.3788 9.37892 14.4434C8.07247 14.2982 6.84666 13.5563 6.13698 12.3305C4.95957 10.2901 5.66118 7.67723 7.7015 6.49175Z"
        fill="url(#paint38_linear)"
      />
      <path
        d="M6.99192 9.12103C7.2547 9.12103 7.46773 9.02354 7.46773 8.90329C7.46773 8.78303 7.2547 8.68555 6.99192 8.68555C6.72914 8.68555 6.51611 8.78303 6.51611 8.90329C6.51611 9.02354 6.72914 9.12103 6.99192 9.12103Z"
        fill="#FFD8A4"
      />
      <path
        d="M10.3144 9.12103C10.5504 9.12103 10.7418 9.02354 10.7418 8.90329C10.7418 8.78303 10.5504 8.68555 10.3144 8.68555C10.0783 8.68555 9.88696 8.78303 9.88696 8.90329C9.88696 9.02354 10.0783 9.12103 10.3144 9.12103Z"
        fill="#FFD8A4"
      />
      <path
        d="M7.30637 8.87897H7.27411C7.16928 8.87897 7.08057 8.79026 7.08057 8.68542V8.258C7.08057 8.15316 7.16928 8.06445 7.27411 8.06445H7.30637C7.41121 8.06445 7.49992 8.15316 7.49992 8.258V8.68542C7.49186 8.79832 7.41121 8.87897 7.30637 8.87897Z"
        fill="white"
      />
      <path
        d="M10.0484 8.87897C9.94357 8.87897 9.85486 8.79026 9.85486 8.68542V8.258C9.85486 8.15316 9.94357 8.06445 10.0484 8.06445C10.1532 8.06445 10.242 8.15316 10.242 8.258V8.68542C10.242 8.79832 10.1532 8.87897 10.0484 8.87897Z"
        fill="white"
      />
      <path
        d="M9.07264 9.12131C8.98393 9.1697 8.78231 9.25035 8.52425 9.24228C8.30651 9.23422 8.14522 9.16164 8.05651 9.12131C8.00006 9.12131 7.9436 9.07293 7.9436 9.00841C7.9436 8.95196 7.99199 8.89551 8.05651 8.89551C8.12909 8.94389 8.29844 9.0326 8.52425 9.04067C8.79844 9.04873 9.00005 8.93583 9.07264 8.89551C9.12909 8.89551 9.18554 8.94389 9.18554 9.00841C9.18554 9.07293 9.12909 9.12131 9.07264 9.12131Z"
        fill="white"
      />
      <defs>
        <linearGradient id="paint0_linear" x1="0" y1="10" x2="20" y2="10" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F2CF00" />
          <stop offset="1" stopColor="#F39800" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="2.82263" y1="10" x2="17.8226" y2="10" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2CF00" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="4.00632"
          y1="-1.31389"
          x2="21.8827"
          y2="6.15927"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="2.1326"
          y1="-0.100969"
          x2="21.0346"
          y2="4.15523"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="0.498055"
          y1="1.41889"
          x2="19.8523"
          y2="2.32766"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="-0.847605"
          y1="3.19807"
          x2="18.3698"
          y2="0.731628"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="-1.8635"
          y1="5.18454"
          x2="16.6335"
          y2="-0.581953"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="-2.52071"
          y1="7.31882"
          x2="14.6946"
          y2="-1.57073"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="-2.79549"
          y1="9.53308"
          x2="12.6144"
          y2="-2.21128"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="-2.6812"
          y1="11.7626"
          x2="10.455"
          y2="-2.47999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint10_linear"
          x1="-2.18225"
          y1="13.9364"
          x2="8.28057"
          y2="-2.37091"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint11_linear"
          x1="-1.31401"
          y1="15.9935"
          x2="6.15904"
          y2="-1.88279"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint12_linear"
          x1="-0.103139"
          y1="17.8688"
          x2="4.15307"
          y2="-1.03315"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint13_linear"
          x1="1.41765"
          y1="19.5025"
          x2="2.32644"
          y2="0.148294"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint14_linear"
          x1="3.19803"
          y1="20.8468"
          x2="0.731565"
          y2="1.62931"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint15_linear"
          x1="5.18513"
          y1="21.8616"
          x2="-0.581288"
          y2="3.36467"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint16_linear"
          x1="7.31697"
          y1="22.5215"
          x2="-1.57264"
          y2="5.30611"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint17_linear"
          x1="9.53233"
          y1="22.7955"
          x2="-2.21209"
          y2="7.3856"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint18_linear"
          x1="11.7628"
          y1="22.6811"
          x2="-2.47969"
          y2="9.54499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint19_linear"
          x1="13.9374"
          y1="22.1803"
          x2="-2.36993"
          y2="11.7175"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint20_linear"
          x1="15.9936"
          y1="21.3141"
          x2="-1.88267"
          y2="13.841"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint21_linear"
          x1="17.8667"
          y1="20.1041"
          x2="-1.03536"
          y2="15.8479"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint22_linear"
          x1="19.502"
          y1="18.583"
          x2="0.147793"
          y2="17.6743"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint23_linear"
          x1="20.8466"
          y1="16.8008"
          x2="1.62903"
          y2="19.2673"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint24_linear"
          x1="21.8622"
          y1="14.8132"
          x2="3.36516"
          y2="20.5797"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint25_linear"
          x1="22.5193"
          y1="12.6836"
          x2="5.30402"
          y2="21.573"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint26_linear"
          x1="22.7945"
          y1="10.4675"
          x2="7.38461"
          y2="22.2117"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint27_linear"
          x1="22.6814"
          y1="8.23697"
          x2="9.54517"
          y2="22.4796"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint28_linear"
          x1="22.1814"
          y1="6.06127"
          x2="11.7188"
          y2="22.3685"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint29_linear"
          x1="21.3142"
          y1="4.00668"
          x2="13.841"
          y2="21.8831"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint30_linear"
          x1="20.1023"
          y1="2.13497"
          x2="15.8462"
          y2="21.0367"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint31_linear"
          x1="18.5826"
          y1="0.49886"
          x2="17.6738"
          y2="19.8529"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint32_linear"
          x1="16.801"
          y1="-0.846721"
          x2="19.2675"
          y2="18.3707"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint33_linear"
          x1="14.8134"
          y1="-1.86359"
          x2="20.5799"
          y2="16.6334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint34_linear"
          x1="12.6816"
          y1="-2.51836"
          x2="21.5711"
          y2="14.697"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint35_linear"
          x1="10.4664"
          y1="-2.79412"
          x2="22.2108"
          y2="12.6158"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint36_linear"
          x1="8.23736"
          y1="-2.68156"
          x2="22.48"
          y2="10.4546"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint37_linear"
          x1="6.06202"
          y1="-2.18288"
          x2="22.3692"
          y2="8.27976"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39800" />
          <stop offset="1" stopColor="#F2C900" />
        </linearGradient>
        <linearGradient
          id="paint38_linear"
          x1="5.56496"
          y1="10.1945"
          x2="13.1841"
          y2="10.1945"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F2CF00" />
          <stop offset="1" stopColor="#F39800" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default IconPoint;
