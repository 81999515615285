import React from 'react';
import { Tabs } from 'antd';
import SingleUpgrade from './component/SingleUpgrade';
import BundleUpgrade from './component/BundleUpgrade';
import { USER_TAB } from '../../common';
import { useState } from 'react';

const { TabPane } = Tabs;

function User() {
  const [curTab, setCurTab] = useState(USER_TAB.SINGLE);

  const handleChangeTab = (activeKey) => {
    setCurTab(activeKey);
  }

  return (
    <div className="user-management">
      <Tabs defaultActiveKey={USER_TAB.SINGLE} className='user__tab' onChange={handleChangeTab}>
        <TabPane tab={'Single Upgrade'} key={USER_TAB.SINGLE}>
          <SingleUpgrade curTab={curTab} />
        </TabPane>
        <TabPane tab={'Bundle Upgrade'} key={USER_TAB.BUNDLE}>
          <BundleUpgrade curTab={curTab} />
        </TabPane>
      </Tabs>
    </div>
  );
}
export default User;
