import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import { getConfigAPI, updateConfigAPI } from '../../services/ConfigCommonRequest';

function* getConfig(action) {
  try {
    const response = yield getConfigAPI(action.body);
    yield put(actions.getConfigSuccess(response));
  } catch (e) {
    yield put(actions.getConfigError(e));
  }
}

function* updateConfig(action) {
  try {
    const response = yield updateConfigAPI(action.body);

    yield put(actions.updateConfigSuccess(response));
  } catch (e) {
    yield put(actions.updateConfigError(e));
  }
}

export function* watchConfigCommon() {
  yield takeLatest(ActionTypes.GET_CONFIG_START, getConfig);
  yield takeLatest(ActionTypes.UPDATE_CONFIG_START, updateConfig);
}
