import { Table } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { USER_TAB } from '../../../common';
import ButtonCommon from '../../../components/Button';
import { actions } from '../actions';
import { renderBundleColumn } from './columns';

function BundleUpgrade({ curTab }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  const bundleUsers = useSelector((state) => state.UsersReducer.bundleUsers);

  const onChangeValue = (event) => {
    const _value = event.target.value;
    setValue(_value);
  }

  useEffect(() => {
    setDataSource(bundleUsers);
  }, [bundleUsers])

  useEffect(() => {
    if (curTab === USER_TAB.BUNDLE) {
      setValue('');
      setDataSource([]);
    }
  }, [curTab])

  const handleSubmit = () => {
    setLoading(true);
    dispatch(actions.upgradeBundleUserStart({ listRequest: value }, () => { setLoading(false) }));
  }

  return (
    <div className="bundle_tab">
      <div className="upgrade-panel">
        <TextArea value={value} onChange={(e) => onChangeValue(e)} rows={8} placeholder="Email | Package Code | Days | Amount | Name Of Sale | Package Status" />
        <ButtonCommon onClick={handleSubmit} className="btn-default orange" title="Upgrade" loading={loading} />
      </div>
      {dataSource.length > 0 && (
        <Table
          bordered
          dataSource={dataSource}
          columns={renderBundleColumn()}
          className="table"
          pagination={false}
          scroll={{x:1024}}
        />
      )}
    </div>
  );
}
export default BundleUpgrade;
