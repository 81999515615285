import React from "react";

function IconPhoto() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="eye-close"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C4.51485 8.46819 7.97447 6 12 6C16.0255 6 19.4851 8.46819 21 12C19.4851 15.5318 16.0255 18 12 18C7.97447 18 4.51485 15.5318 3 12ZM16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C12.6389 8 13.2428 8.14979 13.7786 8.41618C13.305 8.78193 13 9.35535 13 10C13 11.1046 13.8954 12 15 12C15.3608 12 15.6993 11.9045 15.9915 11.7373C15.9971 11.8242 16 11.9117 16 12Z"
        fill="#B2B2B2"
      />
      <path
        d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z"
        fill="#B2B2B2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7678 19.9472L3.99999 5.17939L5.17938 4L19.9472 18.7678L18.7678 19.9472Z"
        fill="#B2B2B2"
      />
    </svg>
  );
}

export default IconPhoto;
