import { validateStatus } from '../utils/validateStatus';
import { typeOfAntDesign } from '../common';
import { getI18n } from 'react-i18next';
import showMessage from '../components/Messsage';
import { message } from 'antd';

const axios = require('axios');

const HEADERS = {
  // "Authorization": "Basic " + base64.encode(Config.AUTH_USER_NAME + ":" + Config.AUTH_PASSWORD),
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
};

const HEADERS_MULTIPLE_PART = {
  ...HEADERS,
  'Content-Type': 'multipart/form-data; boundary=something',
  Accept: 'application/json',
};

export const getToken = (token) => {
  HEADERS['Authorization'] = `Bearer ${token}`;
  HEADERS_MULTIPLE_PART['Authorization'] = `Bearer ${token}`;
};

const getFullUrl = (url) => {
  if (!url.startsWith('/')) {
    url = '/' + url;
  }
  return `${process.env.REACT_APP_API_URL}/api` + url;
};

const resetToLogin = () => {
  const promiseList = [];
  promiseList.push(localStorage.removeItem('persist:root'));
  // promiseList.push(store.dispatch(actions.postLogoutSuccess()));
  Promise.all(promiseList)
    .then((resolvedList) => {
      showMessage(typeOfAntDesign.ERROR, `message.unauthorized`);
    })
    .catch((error) => {
      showMessage(typeOfAntDesign.ERROR, `message.unauthorized`);
    });
};

const checkErrorStatus = (response) => {
  if (response?.status === 200 || response.statusCode === 0) {
    return response.data;
  }
  console.log("RES: ---", response);
  message.error(response.data.message);
  return response;
};

const showErrorStatus = (response, body) => {
  console.log("BODY: ---", response);
  if (response?.status === 200 || response.statusCode === 0) {
    return response;
  }
  console.log("RES Telegram: ---", response);
  const messageError = response?.data?.description;
  if (messageError === 'Bad Request: invalid user_id specified') {
    message.error(`Invalid user_id: ${body.user_id} - ${body.chat_id}`);
    return response;
  }
  if (messageError === 'Bad Request: chat not found') {
    message.error(`Invalid chat_id: ${body.user_id} - ${body.chat_id}`);
    return response;
  }
  message.error(messageError);
  return response;
};

const checkErrorNetwork = (err) => {
  if (err?.toJSON() && err.toJSON().message === 'Network Error') {
    console.log("ERROR Network");
    return showMessage(typeOfAntDesign.ERROR, getI18n().t(`message.MSG_39`));
  }
  return err;
};

const api = {
  post: (endpoint, params) => {
    return axios.default
      .post(getFullUrl(endpoint), params, {
        headers: HEADERS,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          return checkErrorStatus(response);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      )
      .catch(
        (response) => {
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      );
  },

  postMultipart: (endpoint, params) => {
    return axios.default
      .post(getFullUrl(endpoint), params, {
        headers: HEADERS_MULTIPLE_PART,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      )
      .catch(
        (response) => {
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      );
  },

  get: (endpoint, params = {}) => {
    return axios.default
      .get(getFullUrl(endpoint), {
        params: params,
        headers: HEADERS,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          return checkErrorStatus(response);
        },
        (err) => {
          return (err?.response && checkErrorStatus(err.response)) || checkErrorNetwork(err);
        }
      )
      .catch(
        (response) => {
          return checkErrorStatus(response);
        },
        (err) => {
          return (err?.response && checkErrorStatus(err.response)) || checkErrorNetwork(err);
        }
      );
  },

  put: (endpoint, params) => {
    return axios.default
      .put(getFullUrl(endpoint), params, {
        headers: HEADERS,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          return checkErrorStatus(response);
        },
        (err) => {
          return (err?.response && checkErrorStatus(err.response)) || checkErrorNetwork(err);
        }
      )
      .catch(
        (response) => {
          return checkErrorStatus(response);
        },
        (err) => {
          return (err?.response && checkErrorStatus(err.response)) || checkErrorNetwork(err);
        }
      );
  },

  putMultipart: (endpoint, params) => {
    return axios.default
      .put(getFullUrl(endpoint), params, {
        headers: HEADERS_MULTIPLE_PART,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          return checkErrorStatus(response);
        },
        (err) => {
          return (err?.response && checkErrorStatus(err.response)) || checkErrorNetwork(err);
        }
      )
      .catch(
        (response) => {
          return checkErrorStatus(response);
        },
        (err) => {
          return (err?.response && checkErrorStatus(err.response)) || checkErrorNetwork(err);
        }
      );
  },

  delete: (endpoint, params) => {
    return axios.default
      .delete(getFullUrl(endpoint), {
        params: params,
        headers: HEADERS,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      )
      .catch(
        (response) => {
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      );
  },
};

const telegramApi = {
  post: (endpoint, body) => {
    return axios.default
      .post(endpoint, body, {
        headers: HEADERS,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          console.log("RESPONSE THEN: ---", response);
          return showErrorStatus(response, body);
        },
        (err) => {
          console.log("ERR THEN: ---", err);
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      )
      .catch(
        (response) => {
          console.log("RESPONSE CATCH: ---", response);
          return showErrorStatus(response, body);
        },
        (err) => {
          console.log("ERR CATCH: ---", err);
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      );
  },
};
export { api, telegramApi };
