import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import { getListUserAPI, upgradeUserAPI, getListPackageAPI, upgradeBundleUserAPI, updateUserAPI } from '../../services/UserRequest';

function* getListUser(action) {
  try {
    const response = yield getListUserAPI(action.params);
    if (response.statusCode === 0) {
      yield put(actions.getListUserSuccess(response.data));
    } else {
      yield put(actions.getListUserError({}));
    }
  } catch (e) {
    yield put(actions.getListUserError(e));
  }
}


function* getListPackage(action) {
  try {
    const response = yield getListPackageAPI(action.params);
    if (response.statusCode === 0) {
      yield put(actions.getListPackageSuccess(response.data));
    } else {
      yield put(actions.getListPackageError({}));
    }
  } catch (e) {
    yield put(actions.getListPackageError(e));
  }
}

function* upgradeUser(action) {
  try {
    const response = yield upgradeUserAPI(action.params);
    if (response.statusCode === 0) {
      yield action.callback();
      yield action.callbackSuccess();
      yield put(actions.getListUserStart(action.paramSearch));
      yield put(actions.upgradeUserSuccess(response.data));
    } else {
      yield put(actions.upgradeUserError({}));
      yield action.callback();
    }
  } catch (e) {
    yield put(actions.upgradeUserError(e));
    yield action.callback();
  }
}

function* upgradeBundleUser(action) {
  try {
    const response = yield upgradeBundleUserAPI(action.params);
    if (response.statusCode === 0) {
      // yield showMessage(typeOfAntDesign.SUCCESS, MESSAGE.UPDATE_SUCCESS);
      yield action.callback();
      yield put(actions.upgradeBundleUserSuccess(response.data));
    } else {
      yield action.callback();
      yield put(actions.upgradeBundleUserError({}));
    }
  } catch (e) {
    yield action.callback();
    yield put(actions.upgradeBundleUserError(e));
  }
}

function* updateUser(action) {
  try {
    const response = yield updateUserAPI(action.body);

    yield put(actions.updateUserSuccess(response));
  } catch (e) {
    yield put(actions.updateUserError(e));
  }
}

export function* watchListUser() {
  yield takeLatest(ActionTypes.GET_LIST_USERS_START, getListUser);
  yield takeLatest(ActionTypes.GET_LIST_PACKAGE_START, getListPackage);
  yield takeLatest(ActionTypes.UPGRADE_USER_START, upgradeUser);
  yield takeLatest(ActionTypes.UPGRADE_BUNDLE_USER_START, upgradeBundleUser);
  yield takeLatest(ActionTypes.UPDATE_USER_START, updateUser);
}
