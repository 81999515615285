const DEFAULT_STATE = {
  isLoading: false,
};

export default (state = DEFAULT_STATE, action) => {
  if (action.type.indexOf("START") !== -1) {
    return {
      ...state,
      isLoading: true,
    };
  } else if (action.type.indexOf("ERROR") !== -1 || action.type.indexOf("SUCCESS") !== -1) {
    return {
      ...state,
      isLoading: false,
    };
  }
  return state;
};
