export const ActionTypes = {
  GET_LIST_PAIRS_START: 'GET_LIST_PAIRS_START',
  GET_LIST_PAIRS_SUCCESS: 'GET_LIST_PAIRS_SUCCESS',
  GET_LIST_PAIRS_ERROR: 'GET_LIST_PAIRS_ERROR',

  UPDATE_PAIR_STATUS_START: 'UPDATE_PAIR_STATUS_START',
  UPDATE_PAIR_STATUS_SUCCESS: 'UPDATE_PAIR_STATUS_SUCCESS',
  UPDATE_PAIR_STATUS_ERROR: 'UPDATE_PAIR_STATUS_ERROR',

  RESET_TYPE_OF_ACTION: 'RESET_TYPE_OF_ACTION',
};

export const actions = {
  getListPairsStart: (body) => ({
    type: ActionTypes.GET_LIST_PAIRS_START,
    body,
  }),
  getListPairsSuccess: (payload) => ({
    type: ActionTypes.GET_LIST_PAIRS_SUCCESS,
    payload,
  }),
  getListPairsError: (payload) => ({
    type: ActionTypes.GET_LIST_PAIRS_ERROR,
    payload,
  }),

  updatePairStatusStart: (body) => ({
    type: ActionTypes.UPDATE_PAIR_STATUS_START,
    body,
  }),
  updatePairStatusSuccess: (payload) => ({
    type: ActionTypes.UPDATE_PAIR_STATUS_SUCCESS,
    payload,
  }),
  updatePairStatusError: (payload) => ({
    type: ActionTypes.UPDATE_PAIR_STATUS_ERROR,
    payload,
  }),

  resetTypeOfAction: () => ({
    type: ActionTypes.RESET_TYPE_OF_ACTION,
  }),
};
