import { api } from '../services/api';

export const getListSignalAPI = (body) => {
  return api.get('/admin/signals/all', body);
};

export const updateSignalAPI = (body) => {
  return api.put('/admin/signals/update', body);
};

export const getListStatusSignalAPI = (body) => {
  return api.get('/admin/signals/all-status', body);
};
