import React, { useEffect, Fragment, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

import Modal from '../../components/Modal';
import ButtonCommon from '../../components/Button';
import Select from '../../components/Select';
import InputComponent from '../../components/Input';
import UploadAvatar from '../../components/UploadAvatar'

import { actions } from './actions';
import { useState } from 'react';
import { BANNER_TYPE } from '../../common/constant';

function AddBannerModal({ visible, cancelConfirmModal }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const formikRef = useRef();
  const title = 'Add Banner';


  const initialValues = {
    name: '',
    content: '',
    type: BANNER_TYPE[0].value,
    link: '',
    image: null
  };

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setValues({ ...initialValues})
      formikRef.current.setTouched({});
    }
  }, [visible]);

  const callback = () => {
    setLoading(false);
    cancelConfirmModal();
  }

  const onSubmit = (values) => {
    setLoading(true);
    let formData = new FormData();
    formData.append('name', values.name);
    formData.append('content', values.content);
    formData.append('type', values.type);
    formData.append('link', values.link);
    if (values.image && typeof values.image !== "string") {
      formData.append('image', values.image.originFileObj);
    }
    console.log("values: ", values);
    for (const value of formData.values()) {
      console.log(value);
    }
    dispatch(actions.createBannerStart(formData, callback));
  };

  const handleChangeImage = (file) => {
    console.log("event, ", file)
    formikRef.current.setFieldValue("image", file)
  }

  const handleChangeType = (values) => {
    console.log("values: ", formikRef.current);
    formikRef.current.setFieldValue("type", values)
  }

  return (
    <Modal
      visible={visible}
      title={title}
      onCancel={cancelConfirmModal}
      wrapClassName="package-modal confirm-modal"
      width={345}
      content={
        <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={onSubmit} validationSchema={() => Yup.lazy((values) => {
          let valid = {
            name: Yup.string()
              .required(
                "Name is required"
              ),
            content: Yup.string()
              .required(
                "Content is required"
              ),
            link: Yup.string()
              .required(
                "Link is required"
              )
          };
          return Yup.object().shape(valid);
        })} >
          {({ values, handleSubmit, touched, setFieldValue }) => {
            return (
              <Fragment>
                <div className="content">
                  <Field
                    name="name"
                    labelTitle="Name"
                    placeholder="Enter name"
                    autoComplete={'off'}
                    setValue={setFieldValue}
                    component={InputComponent}
                  />
                  <Field
                    name="content"
                    labelTitle="Content"
                    placeholder="Enter content"
                    autoComplete={'off'}
                    setValue={setFieldValue}
                    component={InputComponent}
                  />
                  <Field
                    name="link"
                    labelTitle="Link"
                    placeholder="Enter link"
                    autoComplete={'off'}
                    setValue={setFieldValue}
                    component={InputComponent}
                  />
                  <Field
                    onChange={handleChangeType}
                    options={BANNER_TYPE}
                    component={Select}
                    name="type"
                    labelTitle="Type"
                    required
                    value={values?.type}
                  />
                  <Field
                    name="image"
                    labelTitle="Image"
                    placeholder="Enter Image"
                    autoComplete={'off'}
                    value={values?.image}
                    handleChangeBanner={handleChangeImage}
                    component={UploadAvatar}
                  />
                </div>
                <div className="button-group gr-2">
                  <ButtonCommon onClick={cancelConfirmModal} className="btn-default orange outline" title="Cancel" />
                  <ButtonCommon onClick={() => onSubmit(values)} className="btn-default orange" title="Add" loading={loading} />
                </div>
              </Fragment>
            )
          }}
        </Formik>
      }
    />
  );
}

export default withTranslation()(AddBannerModal);
