import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Router, Route, Switch } from "react-router-dom";
import { browserHistory } from "./utils/history";
import { PublicLayout, PrivateLayout } from "./pages/Layout";
import { publicRoutes, privateRoutes } from "./Routes";
import { Language } from "./common";
import i18n from "./i18n/i18n";
import { useSelector } from "react-redux";
import Loading from "./components/Loading";

import "./App.css";
import "./sass/_app.scss";
import NotFound from "./pages/NotFound";

function App() {
  const isLoading = useSelector((state) => state.LayoutReducer.isLoading);

  useEffect(() => {
    let language = localStorage.getItem("i18GenesisCompass") || Language.EN;
    if (!Language[language.toUpperCase()]) {
      language = Language.EN;
      i18n.changeLanguage(language);
    }
    return () => {};
  }, []);

  return (
    <div className="App bg-app">
      <Loading isLoading={isLoading} />
      <Router history={browserHistory}>
        <Switch>
          <Route path={publicRoutes.map((item) => item.path)} component={PublicLayout} />
          <Route path={privateRoutes.map((route) => route.path)} component={PrivateLayout} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
}

export default withTranslation()(App);
