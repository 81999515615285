import { telegramApi } from './api';

// unbanChatMember
const endpoint = 'https://api.telegram.org/bot6839223700:AAEZhHcQXTZmzpVMRCu2nCIbvRPFJZ7oHU8/banChatMember';

export const banUserAPI = (params) => {
  console.log('params', params);
  const listUserAndGroup = params?.listRequest?.split('\n');
  return Promise.all(listUserAndGroup.map(async (userAndGroup) => {
    try {
      const userID = userAndGroup.split('|')[0].trim();
      const groupID = userAndGroup.split('|')[1].trim();
      const res = await telegramApi.post(endpoint, { chat_id: groupID, user_id: userID });
      if (res?.status !== 200 && res?.status !== 201) {
        return { userAndGroup, status: 'error', message: res.data.description };
      }
      return null
    } catch (e) {
      console.log('banUserAPI ERROR ', e);
      return { userAndGroup, status: 'error', message: e.message };
    }
  }))
};