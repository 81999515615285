import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import { banUserAPI } from '../../services/TelegramRequest';

function* banUser(action) {
  try {
    const response = yield banUserAPI(action.body);
    if (response.length > 0 && response.some((item) => item !== null)) {
      yield put(actions.banUserError(response));
    } else {
      yield put(actions.banUserSuccess(response));
    }
  } catch (e) {
    yield put(actions.banUserError(e));
  }
}

export function* watchTelegram() {
  yield takeLatest(ActionTypes.BAN_USER_START, banUser);
}
