import React from 'react';
import { UPGRADE_STATUSES } from '../../../common';
import { numberWithCommas } from '../../../utils';

export const renderCollum = (toggleFormModal) => [
  {
    title: 'User Name',
    dataIndex: 'username',
    key: 'username',
    align: 'center',
    width: '15%',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    align: 'left',
    width: '25%',
    ellipsis: true,
  },
  {
    title: 'Register Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'left',
    width: '20%',
  },
  {
    title: 'Package Name',
    dataIndex: 'currentPackage',
    key: 'currentPackage',
    align: 'left',
    width: '10%',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    editable: true,
    align: 'left',
    width: '10%',
    render: (value, record) => {
      return (<p>{value ? `${numberWithCommas(value)} VND` : '--'}</p>)
    }
    ,
  },
  {
    title: 'Name Of Sale',
    dataIndex: 'nameOfSale',
    key: 'nameOfSale',
    editable: true,
    align: 'left',
    width: '20%',
    render: (value, record) => {
      return (<p>{value ? value : '--'}</p>)
    }
  },
  {
    title: 'Upgrade At',
    dataIndex: 'upgradedAt',
    key: 'upgradedAt',
    align: 'center',
    width: '15%'
  },
  {
    title: 'Upgrade To',
    dataIndex: 'upgradedTo',
    key: 'upgradedTo',
    align: 'center',
    width: '15%'
  },
  {
    title: 'Action',
    dataIndex: 'action',
    align: 'center',
    width: '10%',
    className: 'collum_action',
    render: (values, row) => {
      return (
        <div onClick={() => toggleFormModal(row)} className="collum_action_view">
          Upgrade
        </div>
      )
    }
  },
];

export const renderBundleColumn = () => [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    align: 'left',
    width: '30%',
    ellipsis: true,
  },
  {
    title: 'Current Package Name',
    dataIndex: 'currentPackage',
    key: 'currentPackage',
    align: 'left',
    width: '25%',
  },
  {
    title: 'Upgrade Status',
    dataIndex: 'upgradeStatus',
    align: 'center',
    width: '15%',
    render: (value) => {
      const curStatus = Object.values(UPGRADE_STATUSES).find((status) => status?.value === value);
      return (
        <p className={`bg--${curStatus?.bgLabelColor} text--${curStatus?.labelColor} table--status`}>
          {curStatus?.label}
        </p>
      );
    },
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
    key: 'reason',
    align: 'center',
    width: '30%'
  },
];