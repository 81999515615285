import { Layout } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { publicRoutes } from '../../../Routes';
import { getToken } from '../../../services/api';

const { Content } = Layout;

function PublicRoute() {
  const authToken = useSelector((state) => state.LoginReducer.adminInfo?.accessToken);

  if (authToken) {
    getToken(authToken);

    return <Redirect to="/" />;
  }

  return (
    <Layout className="layout">
      <Content>
        <Switch>
          {publicRoutes.map((route, index) => {
            return <Route path={route.path} component={route.component} exact={route.exact} key={index} {...route} />;
          })}
        </Switch>
      </Content>
    </Layout>
  );
}
export default withRouter(PublicRoute);
