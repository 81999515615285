import React, { useEffect, useState } from 'react';
import { browserHistory } from '../utils/history';
import { getI18n as i8 } from 'react-i18next';
import { DATE_FORMAT, objectImageDefault, STRING_TYPE, TEXT_NULL } from '../common';
import moment from 'moment';

export const renderPointOrange = (value) => {
  return i8().t('withdraw.point_orange', { number: formatMoney(value) });
};

export const renderDateTimeBreakLine = (timestamp, style = 'block') => {
  return (
    <>
      <span className={`span-time ${style}`}>{moment(timestamp).format(DATE_FORMAT.DDMMYY_SEARCH)}</span>
      <span>{moment(timestamp).format(DATE_FORMAT.HHMMSS)}</span>
    </>
  );
};
export const renderDateTimeInLine = (timestamp) => {
  return (
    <>
      <span className={`span-time`}>{moment(timestamp).format(DATE_FORMAT.DDMMYY_SEARCH)}</span>
      <span>{moment(timestamp).format(DATE_FORMAT.HHMMSS)}</span>
    </>
  );
};

export const renderParamsSearchDate = (params) => {
  return {
    ...params,
    fromDate: params?.fromDate ? params?.fromDate.format(DATE_FORMAT.DDMMYY_SEARCH) : '',
    toDate: params?.toDate ? params?.toDate.format(DATE_FORMAT.DDMMYY_SEARCH) : '',
  };
};

export const onRedirect = (path) => {
  browserHistory.push(path);
};

export const onClickRedirect = (path) => () => {
  browserHistory.push(path);
};

export const renderValue = (orginal, convert, textNull = TEXT_NULL) => {
  return orginal ? convert || orginal : textNull;
};

export const getArraySelectLocalized = (objectValue, txtLocalized, checkEmpty = false) => {
  const arraySelect = [];
  Object.values(objectValue).forEach((item) => {
    if (checkEmpty && item === null) {
      return;
    }
    arraySelect.push({
      key: item,
      label: i8().t(`${txtLocalized}.value_${item}`),
    });
  });

  return arraySelect;
};

export const convertArrayFile = (url, name) => {
  return {
    ...objectImageDefault,
    url,
    name,
  };
};

export function formatMoney(num) {
  if (!num) return '';
  if (num === 0) return 0;
  return num
    .toString()
    .split('')
    .reverse()
    .reduce(function (acc, num, i, orig) {
      return num === '-' ? acc : num + (i && !(i % 3) ? '.' : '') + acc;
    }, '');
}

export const convertAllCoinsSelect = (data) => {
  const result = convertAllCoinsData(data);
  result.splice(0, 0, {
    key: null,
    label: i8().t('historical.label_search_all'),
  });
  return result;
};

export const convertAllCoinsData = (data) => {
  return data.map((item) => {
    return {
      key: item.id,
      label: item.name,
    };
  });
};

export function setValueDate(value) {
  return value ? moment(value) : '';
}

export const disableEndDate = (value) => (current) => {
  const currentDate = moment(Date.now());
  if (value) {
    return current < value || current > currentDate;
  }
  return current > currentDate;
};

export const disableFeature = () => (current) => {
  const currentDate = moment(Date.now());
  return current > currentDate;
};

export const disableStartDate = (value) => (current) => {
  const convertMoment = moment(value).add(1, 'days').format('YYYY-MM-DD');
  if (value) return current > moment(convertMoment);
  const currentDate = moment(Date.now());
  return current > currentDate;
};

export const getCurrentStringDate = (stringDate = '') => {
  return moment(stringDate ? stringDate : new Date()).format('YYYY-MM-DD');
};

export const convertCurrentDate = (moment) => {
  return typeof moment === STRING_TYPE ? moment : moment.format('YYYY-MM-DD');
};

export const convertDateSearch = (params) => {
  return {
    ...params,
    to: params.to ? params.to : getCurrentStringDate(),
    from: params.from ? params.from : '',
  };
};

export const trimObjValues = (obj) => {
  let objectResult = obj;
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'string') {
      objectResult = { ...objectResult, [key]: value.trim() };
    }
  }
  return objectResult;
};

export const mapEmptyObject = (obj) => {
  let objectResult = obj;
  for (const [key] of Object.entries(obj)) {
    objectResult = { ...objectResult, [key]: '' };
  }
  return objectResult;
};

export const sortDirection = (order) => {
  switch (order) {
    case 'descend':
      return 'desc';
    case 'ascend':
      return 'asc';
    default:
      return '';
  }
};

export const blockCharacter = (e) => {
  if (!(/[0-9]/g.test(e.key) || e.key === 'Backspace')) {
    return e.preventDefault();
  }
};

export const getParamsChangePage = (paramsSearch, pageNum, pageSize) => {
  return {
    ...paramsSearch,
    offset: (pageNum - 1) * pageSize,
    limit: pageSize,
  };
};

export const getParamsSort = (paramsSearch, sorter) => {
  const { order, field } = sorter;
  return {
    ...paramsSearch,
    orderDirection: sortDirection(order),
    orderBy: order ? field : '',
  };
};

export const convertDateToFormat = (date, format = 'DD/MM/YYYY') => {
  return moment(date).format(format);
};
export const formatDatePicker = (date, format = 'DD/MM/YYYY') => {
  return moment(date, format);
};

export const numberWithCommas = (number) => {
  return number && (number >= 1000 ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : number);
};

export const convertTimestamp = (timestamp) => {
  console.log('timeStamp: ', timestamp);
  const date = new Date(timestamp * 1000);
  return date.toLocaleDateString();
}

const isClient = typeof window === 'object';

function getSize() {
  return {
    width: isClient ? window.innerWidth : 0,
    height: isClient ? window.innerHeight : 0,
  };
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }

    if (isClient) {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
    return undefined;
  }, []);

  return windowSize;
}