import React, { useEffect, useMemo, useState } from 'react';
import { Button, Table, Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { actions } from '.././actions';
import { DEFAULT_INITIAL_PAGE, DEFAULT_PAGE_SIZE, NUMBER_REGEX } from '../../../common/constant';
import { trim } from 'lodash-es';
import { LIST_DURATION, REPORT_TAB } from '../../../common';
import { columnSaleReport } from './columns';
import ButtonCommon from '../../../components/Button';
import { getExportReportUserAPI } from '../../../services/ReportRequest';

const { Option } = Select;

function ReportUser({ curTab }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const listUser = useSelector((state) => state.ReportReducer.reportUser);

  const [time, setTime] = useState('');
  const [duration, setDuration] = useState('D');

  const queryParams = useMemo(() => {
    const params = queryString.parse(location.search);

    return {
      ...params,
      size: Number.parseInt(params?.size) || DEFAULT_PAGE_SIZE,
      page: Number.parseInt(params?.page) || DEFAULT_INITIAL_PAGE,
    };
  }, [location.search]);

  const onSearch = () => {
    history.push({
      pathname: history.location.pathname,
      search: queryString.stringify({ ...queryParams, number: trim(time), time: duration }),
    });
    dispatch(actions.getListReportUserStart(queryParams));
  };

  useEffect(() => {
    setDuration('D')
    setTime('')
    history.push({
      pathname: history.location.pathname,
      search: queryString.stringify({}),
    });
  }, [curTab])

  const handleSearchChange = (event) => {
    const { value } = event.target;
    if (NUMBER_REGEX.test(value)) {
      setTime(value);
    }
  };

  const handlePageChange = (page) => {
    history.push({
      pathname: history.location.pathname,
      search: queryString.stringify({ ...queryParams, page: page - 1 }),
    });
  };

  const onChangeSelect = (value) => {
    setDuration(value);
  }

  const onExport = async () => {
    try {
      const response = await getExportReportUserAPI(queryParams);
      const fileUrl = `${process.env.REACT_APP_API_URL}${response?.data}`
      const link = document.createElement('a');

      link.href = fileUrl;
      link.target = '_blank';
      link.click();
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (curTab === REPORT_TAB.REPORT_USER) {
      dispatch(actions.getListReportUserStart(queryParams));
    }
  }, [dispatch, queryParams, curTab]);

  return (
    <div className="user-tab">
      <div className="search-panel">
        <Input allowClear placeholder='Enter Number' maxLength={8} onPressEnter={onSearch} onChange={handleSearchChange} value={time} />
        <Select
          value={duration}
          onChange={(value) => onChangeSelect(value)}
        >
          {LIST_DURATION?.map((item, index) => (
            <Option key={index} value={item.value}>{item.label}</Option>
          ))}
        </Select>
        <Button onClick={onSearch}>Search</Button>
        {listUser?.totalElements > 0 && <ButtonCommon onClick={onExport} className="btn-default orange" title="Export CSV" />}
      </div>
      <Table
        bordered
        dataSource={listUser?.content}
        columns={columnSaleReport()}
        className="table"
        pagination={{
          current: queryParams.page + 1,
          pageSize: queryParams.size,
          total: listUser?.totalElements,
          onChange: handlePageChange,
        }}
        scroll={{x:1024}}
      />
    </div>
  );
}
export default ReportUser;
