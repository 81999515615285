import HomeManagement from './pages/Home';
import LoginPage from './pages/Login';
import Report from './pages/Report';
import Signal from './pages/Signal';
import Banner from './pages/Banners';
import ConfigCommon from './pages/ConfigCommon';
import User from './pages/UserManagement';
import Telegram from './pages/Telegram';

const publicRoutes = [
  {
    title: 'Login',
    path: '/login',
    component: LoginPage,
    exact: true,
  },
];

const privateRoutes = [
  {
    name: 'Home',
    path: '/',
    component: HomeManagement,
    exact: true,
  },
  {
    name: 'Signal',
    path: '/signal',
    component: Signal,
    exact: true,
  },
  {
    name: 'Users',
    path: '/users',
    component: User,
    exact: true,
  },
  {
    name: 'Report',
    path: '/reports',
    component: Report,
    exact: true,
  },
  {
    name: 'Banners',
    path: '/banners',
    component: Banner,
    exact: true,
  },
  {
    name: 'Telegram',
    path: '/telegram',
    component: Telegram,
    exact: true,
  },
  {
    name: 'Config Common',
    path: '/config',
    component: ConfigCommon,
    exact: true,
  },
];

export { publicRoutes, privateRoutes };
