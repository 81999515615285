import { all } from 'redux-saga/effects';
import { watchLogin } from '../pages/Login/saga';
import { watchSignal } from '../pages/Signal/saga';
import { watchConfigCommon } from '../pages/ConfigCommon/saga';
import { watchBanner } from '../pages/Banners/saga';
import { watchPairs } from '../pages/Home/saga';
import { watchListUser } from '../pages/UserManagement/saga';
import { watchReportSaga } from '../pages/Report/saga';
import { watchTelegram } from '../pages/Telegram/saga';

function* rootSaga() {
  yield all([watchLogin(), watchSignal(), watchPairs(), watchListUser(), watchReportSaga(), watchConfigCommon(), watchBanner(), watchTelegram()]);
}
export default rootSaga;
