import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Button, Space, Input } from 'antd';
import { EditableContext } from '../EditableRow';
import UploadAvatar from '../../../components/UploadAvatar';
import SelectComponent from '../../../components/Select';
import { BANNER_TYPE } from '../../../common/constant';

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing && inputRef.current) {
      console.log("inputRef.current", inputRef.current);
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const handleChangeImage = (file) => {
    console.log("event, ", file)
    form.setFieldsValue({
      image: file.originFileObj,
    });
  }

  const handleChangeType = (value) => {
    console.log("value, ", value)
    form.setFieldsValue({
      type: value,
    });
  }

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <div className="editable-cell">
        {dataIndex === 'name' && (
          <Space>
            <Form.Item
              style={{
                margin: 0,
              }}
              name={dataIndex}
              rules={[
                {
                  required: true,
                  message: `${title} is required.`,
                },
              ]}
            >
              <Input
                ref={inputRef}
                onPressEnter={save}
              />
            </Form.Item>
          </Space>
        )}
        {dataIndex === 'content' && (
          <Space>
            <Form.Item
              style={{
                margin: 0,
              }}
              name={dataIndex}
              rules={[
                {
                  required: true,
                  message: `${title} is required.`,
                },
              ]}
            >
              <Input
                ref={inputRef}
                onPressEnter={save}
              />
            </Form.Item>
          </Space>
        )}
        {dataIndex === 'link' && (
          <Space>
            <Form.Item
              style={{
                margin: 0,
              }}
              name={dataIndex}
              rules={[
                {
                  required: true,
                  message: `${title} is required.`,
                },
              ]}
            >
              <Input
                ref={inputRef}
                onPressEnter={save}
              />
            </Form.Item>
          </Space>
        )}
        {dataIndex === 'type' && (
          <Space>
            <Form.Item
              style={{
                margin: 0,
              }}
              name={dataIndex}
              rules={[
                {
                  required: true,
                  message: `${title} is required.`,
                },
              ]}
            >
              <SelectComponent
                options={BANNER_TYPE}
                onChange={handleChangeType}
                form
                field={{ name: "type", value: BANNER_TYPE[0].value }}
                name="type"
                labelTitle="Type"
                value={BANNER_TYPE[0].value}
              />
            </Form.Item>
          </Space>
        )}
        {dataIndex === 'image' && (
          <Space>
            <Form.Item
              style={{
                margin: 0,
              }}
              name={dataIndex}
              rules={[
                {
                  required: true,
                  message: `${title} is required.`,
                },
              ]}
            >
              <UploadAvatar
                field={{ value: "" }}
                handleChangeBanner={handleChangeImage}
              />
            </Form.Item>
          </Space>
        )}
        <Space size="middle" className="editable-cell__action">
          <Button type="link" onClick={save}>
            Save
          </Button>
          <Button type="link" onClick={toggleEdit}>
            Cancel
          </Button>
        </Space>
      </div>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;
