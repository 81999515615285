import * as Yup from 'yup';

export const validateStatus = (status) => {
  return status === 200 || status === 201 || status === 400 || status === 401 || status === 500;
};

export const validateChangePassword = (t) => {
  return Yup.object({
    oldPassword: Yup.string().required(t('message.MSG_03', { fieldName: t('password.current_password') })),
    password: Yup.string()
      .required(t('message.MSG_03', { fieldName: t('password.new_pass') }))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        t('message.MSG_08', { fieldName: t('password.new_pass') })
      )
      .when('oldPassword', {
        is: (val) => !!val,
        then: Yup.string().notOneOf([Yup.ref('oldPassword'), null], t('message.MSG_13')),
      }),
    newPasswordConfirm: Yup.string()
      .required(t('message.MSG_03', { fieldName: t('password.confirm_pass') }))
      .oneOf([Yup.ref('password'), null], t('message.MSG_12', { fieldName: t('password.confirm_pass') })),
  });
};

export const validateForgotPassword = (t) => {
  return Yup.object({
    code: Yup.string().required(getValidateEmpty(t, 'password.verify_code')),
    password: Yup.string()
      .required(getValidateEmpty(t, 'password.new_pass'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        t('message.MSG_08', { fieldName: t('password.new_pass') })
      ),
    newPasswordConfirm: Yup.string()
      .required(t('message.MSG_03', { fieldName: t('password.confirm_pass') }))
      .oneOf([Yup.ref('password'), null], t('message.MSG_12', { fieldName: t('password.confirm_pass') })),
  });
};

export const validateLogin = (t) => {
  return Yup.object({
    email: Yup.string().required(getValidateEmpty(t, 'login.email')),
    password: Yup.string().required(getValidateEmpty(t, 'login.password')),
  });
};

export const validateFonfirmEmail = (t) => {
  return Yup.object({
    email: Yup.string()
      .required(getValidateEmpty(t, 'login.email'))
      .email(t('message.MSG_04', { fieldName: t('common.email') })),
  });
};

export const validateReasonReject = (t) => {
  return Yup.object({
    reason: Yup.string().required(getValidateEmpty(t, 'channel.pleace_reason_reject')),
  });
};

export const validateReasonRejectWithdraw = (t) => {
  return Yup.object({
    reason: Yup.string().required(getValidateEmpty(t, 'withdraw.reject_reason')),
  });
};

export const getValidateEmpty = (t, fieldName, unescape = false) => {
  const message = `message.MSG_03${unescape ? '_Unescape' : ''}`;
  return t(message, { fieldName: unescape === 0 ? fieldName : t(fieldName) });
};

export const getValidateFormatUrl = (t, fieldName) => {
  const message = `message.MSG_07_website`;
  return t(message, { fieldName: t(fieldName) });
};

export function validateNumber(value) {
  if (!value) return true;
  var re = /^[0-9]+$/;
  return re.test(value);
}

export function validateNumberDecimal(value) {
  if (!value) return true;
  var re = /^-{0,1}\d*\.{0,1}\d+$/;
  return re.test(value);
}

export function validateNormalText(value) {
  if (!value) return true;
  var re = /^[A-Za-z0-9 ]+$/;
  return value.match(re);
}

export const blockSpecialChar = (setFieldValue, name) => ({ target: { value } }) => {
  if (!validateNumber(value)) return;
  setFieldValue(name, value);
};

export const blockFormatMoney = (setFieldValue, name) => ({ target: { value } }) => {
  value = value.replace(/,/g, '');
  if (!validateNumberDecimal(value)) return;
  setFieldValue(name, value);
};

export const blockNormal = (setFieldValue, name) => ({ target: { value } }) => {
  if (!validateNormalText(value)) return;
  if (value.indexOf(' ') === 0 || value.indexOf('  ') !== -1) return;
  setFieldValue(name, value);
};

export const trimValue = (setFieldValue, name) => ({ target: { value } }) => {
  setFieldValue(name, value.trim());
};
