import React from 'react';
import { Tabs } from 'antd';
import ReportSale from './component/ReportSale';
import ReportUser from './component/ReportUser';
import { REPORT_TAB } from '../../common';
import { useState } from 'react';

const { TabPane } = Tabs;

function Report() {
  const [curTab, setCurTab] = useState(REPORT_TAB.REPORT_SALE);

  const HandleChangeTab = (activeKey) => {
    setCurTab(activeKey);
  }

  return (
    <div className="report">
      <Tabs defaultActiveKey={REPORT_TAB.REPORT_SALE} activeKey={curTab} className='report__tab' onChange={HandleChangeTab}>
        <TabPane tab={'Report Sale'} key={REPORT_TAB.REPORT_SALE}>
          <ReportSale curTab={curTab} />
        </TabPane>
        <TabPane tab={'Report User'} key={REPORT_TAB.REPORT_USER}>
          <ReportUser curTab={curTab} />
        </TabPane>
      </Tabs>
    </div>
  );
}
export default Report;
