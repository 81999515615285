import React, { useEffect, Fragment, useRef, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

import Modal from '../../../components/Modal';
import ButtonCommon from '../../../components/Button';
import Select from '../../../components/Select';
import InputComponent from '../../../components/Input';

import { actions } from '../actions';
import { NUMBER_REGEX } from '../../../common/constant';
import { ACTIVE_STATUS_LIST, typeOfAntDesign } from '../../../common';
import { useState } from 'react';
import showMessage from '../../../components/Messsage';

function UpgradeModal({ visible, user, paramsSearch, cancelConfirmModal }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(actions.getListPackageStart());
  }, []);

  const formikRef = useRef();
  const title = 'Upgrade user';


  const listPackage = useSelector((state) => state?.UsersReducer?.listPackage);

  const dataSelectPackage = useMemo(() => {
    const data = listPackage?.filter(value => value?.name !== "ROLE_FREE_USER")?.sort((a, b) => {
      return a?.days - b?.days;
    })
    return data?.map((item, index) => {
      return {
        label: item?.packageName,
        key: index,
        value: item?.name,
        days: item?.days
      };
    });
  }, [listPackage]);

  const defaultValueSelect = useMemo(() => {
    return dataSelectPackage?.[0];
  }, [dataSelectPackage]);


  const initialValues = {
    packageName: defaultValueSelect?.key,
    days: defaultValueSelect?.days,
    email: user?.email,
    amount: '',
    nameOfSale: '',
    activeStatus: null
  };

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setValues({ ...initialValues, email: user?.email, packageName: user?.currentPackage })
      formikRef.current.setTouched({});
    }
  }, [user, formikRef]);

  const callbackUpdateSuccess = () => {
    showMessage(typeOfAntDesign.SUCCESS, `User ${user?.email} is successfully updated for ${formikRef.current.values?.days} days.`)
  }

  const callback = () => {
    setLoading(false);
    cancelConfirmModal();
  }

  const onSubmit = (values) => {
    setLoading(true);
    const data = {
      ...values,
      createdDate: Math.floor(Date.now() / 1000),
    };
    dispatch(actions.upgradeUserStart(data, paramsSearch, callback, callbackUpdateSuccess));
  };

  const handleChangeNumber = (event, field) => {
    const { value } = event.target;
    if (NUMBER_REGEX.test(value)) {
      formikRef.current.setFieldValue(field, value);
    }
  };

  const handleChangePackage = (values) => {
    const currentPackage = listPackage.find(item => item.name === values)
    formikRef.current.setFieldValue("packageName", values)
    formikRef.current.setFieldValue("days", currentPackage?.days)
  }

  const handleChangeStatus = (values) => {
    formikRef.current.setFieldValue("activeStatus", values);
  }


  return (
    <Modal
      visible={visible}
      title={title}
      onCancel={cancelConfirmModal}
      wrapClassName="package-modal confirm-modal"
      width={345}
      content={
        <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={onSubmit} validationSchema={() => Yup.lazy((values) => {
          let valid = {
            days: Yup.string()
              .required(
                "Days is required"
              ),
            amount: Yup.string()
              .required(
                "Amount is required"
              ),
            nameOfSale: Yup.string()
              .required(
                "Name Of Sale is required"
              ),
            activeStatus: Yup.object()
              .nullable(
                "Active Status is required"
              )
              .test(function (value) {
                value = value?.replaceAll(',', '');
                const { path, createError } = this;
                if (
                  values.activeStatus === null
                ) {
                  return createError({
                    path,
                    message: "Active Status is required",
                  });
                }
              }),
          };
          return Yup.object().shape(valid);
        })} >
          {({ values, handleSubmit, touched, setFieldValue }) => {
            return (
              <Fragment>
                <div className="content">
                  <Field
                    name="email"
                    labelTitle="Email"
                    placeholder="Enter email"
                    disabled
                    autoComplete={'off'}
                    setValue={setFieldValue}
                    component={InputComponent}
                  />
                  <Field
                    setValues={setFieldValue}
                    onChange={handleChangePackage}
                    options={dataSelectPackage}
                    component={Select}
                    name="packageName"
                    labelTitle="Package Name"
                    required
                    value={values.packageName}
                  />

                  <Field
                    name="days"
                    labelTitle="Days"
                    placeholder="Enter Days"
                    required
                    autoComplete={'off'}
                    onChange={(e) => handleChangeNumber(e, 'days')}
                    component={InputComponent}
                  />
                  <Field
                    name="amount"
                    labelTitle="Amount (VND)"
                    placeholder="Enter Amount"
                    required
                    autoComplete={'off'}
                    onChange={(e) => handleChangeNumber(e, 'amount')}
                    component={InputComponent}
                  />
                  <Field
                    name="nameOfSale"
                    labelTitle="Name Of Sale"
                    placeholder="Enter Name Of Sale"
                    required
                    autoComplete={'off'}
                    component={InputComponent}
                  />
                  <Field
                    setValues={setFieldValue}
                    options={ACTIVE_STATUS_LIST}
                    component={Select}
                    onChange={handleChangeStatus}
                    onBlur={() => {
                      formikRef.current.setTouched({ ...touched, activeStatus: true });
                    }}
                    name="activeStatus"
                    labelTitle="Active Status"
                    placeholder="Select Active Status"
                    required
                    value={values.activeStatus}
                  />
                </div>
                <div className="button-group gr-2">
                  <ButtonCommon onClick={cancelConfirmModal} className="btn-default orange outline" title="Cancel" />
                  <ButtonCommon onClick={() => onSubmit(values)} className="btn-default orange" title="Upgrade" loading={loading} />
                </div>
              </Fragment>
            )
          }}
        </Formik>
      }
    />
  );
}

export default withTranslation()(UpgradeModal);
