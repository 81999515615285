import { ActionTypes } from './actions';

const DEFAULT_STATE = {
  config: 0,
  type: '',
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_CONFIG_SUCCESS:
      return {
        ...state,
        config: action.payload.data,
      };
    case ActionTypes.GET_CONFIG_ERROR:
      return {
        ...state,
        config: action.payload,
      };

    case ActionTypes.UPDATE_CONFIG_SUCCESS:
    case ActionTypes.UPDATE_CONFIG_ERROR:
      return {
        ...state,
        type: action.type,
      };

    case ActionTypes.RESET_TYPE_OF_ACTION:
      return {
        ...state,
        type: '',
      };
    default:
      return state;
  }
};
