import { combineReducers } from 'redux';
import LoginReducer from '../pages/Login/reducer';
import LayoutReducer from '../pages/Layout/reducer';
import SignalReducer from '../pages/Signal/reducer';
import ConfigCommonReducer from '../pages/ConfigCommon/reducer';
import BannerReducer from '../pages/Banners/reducer';
import PairsReducer from '../pages/Home/reducer';
import UsersReducer from '../pages/UserManagement/reducer';
import ReportReducer from '../pages/Report/reducer';
import TelegramReducer from '../pages/Telegram/reducer';

export default combineReducers({
  LayoutReducer,
  LoginReducer,
  SignalReducer,
  ConfigCommonReducer,
  BannerReducer,
  PairsReducer,
  UsersReducer,
  ReportReducer,
  TelegramReducer,
});
