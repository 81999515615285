import React from 'react';
import { Select } from 'antd';

function SelectComponent({
  field,
  form: { touched, errors },
  labelTitle,
  options = [],
  wrapperClass = '',
  required,
  setValues,
  resetValue = false,
  listField = [],
  ...props
}) {
  const onChangeValue = (value) => {
    console.log("onChangeValue", value);
    setValues(field.name, value);
    if (resetValue) {
      listField.forEach((item) => {
        setValues(item, '');
      });
    }
  };
  return (
    <div className={`select ${wrapperClass}`}>
      {labelTitle && (
        <label htmlFor={field.name}>
          {labelTitle} {required && <span className="required">*</span>}
        </label>
      )}
      <Select onChange={onChangeValue} options={options} className={labelTitle ? 'label' : ''} {...props}>
      </Select>
      {errors[field.name] && touched[field.name] && !field.value && (
        <label className="required">{errors[field.name]}</label>
      )}
    </div>
  );
}

export default SelectComponent;
