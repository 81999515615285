import React, { useEffect, useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './actions';
import { ActionTypes } from './actions';
import showMessage from '../../components/Messsage';
import { TYPE_OF_ANT_DESIGN } from '../../common';
import ButtonCommon from '../../components/Button';
import { List, Typography } from 'antd';


function TelegramPage() {
  const dispatch = useDispatch();

  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const actionType = useSelector((state) => state.TelegramReducer.type);
  const data = useSelector((state) => state.TelegramReducer.config);

  useEffect(() => {
    if (actionType === ActionTypes.BAN_USER_ERROR) {
      setLoading(false);
      dispatch(actions.resetTypeOfAction());
    }
    if (actionType === ActionTypes.BAN_USER_SUCCESS) {
      setLoading(false);
      showMessage(TYPE_OF_ANT_DESIGN.SUCCESS, 'Ban list user successfully!');
      dispatch(actions.resetTypeOfAction());
    }
  }, [actionType]);

  const onChangeValue = (event) => {
    const _value = event.target.value;
    setValue(_value);
  }

  const handleSubmit = () => {
    setLoading(true);
    console.log('value', value);
    if (!value) {
      showMessage('error', 'Please enter user ID | group ID');
      setLoading(false)
      return;
    }
    dispatch(actions.banUserStart({ listRequest: value }));
  }

  return (
    <div className="upgrade-panel">
      <TextArea value={value} onChange={(e) => onChangeValue(e)} rows={8} placeholder="User ID | Group ID" />
      <ButtonCommon onClick={handleSubmit} className="btn-default orange telegram-delete" title="Delete" loading={loading} />
      {data && data.length > 0 && <List
        header={<div>List user banned failed</div>}
        bordered
        dataSource={data.filter((item) => item?.status === 'error')}
        renderItem={(item) => (
          <List.Item>
            {item.userAndGroup} - {item.message}
          </List.Item>
        )}
      />}
    </div>
  );
}

export default TelegramPage;
