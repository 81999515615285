import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import { createBannerAPI, deleteBannerAPI, getListBannerAPI, updateBannerAPI } from '../../services/BannerRequest';

function* getListBanner(action) {
  try {
    const response = yield getListBannerAPI(action.body);
    yield put(actions.getListBannerSuccess(response));
  } catch (e) {
    yield put(actions.getListBannerError(e));
  }
}

function* createBanner(action) {
  try {
    const response = yield createBannerAPI(action.body);

    yield put(actions.createBannerSuccess(response));
    yield action.callback();
  } catch (e) {
    yield put(actions.createBannerError(e));
    yield action.callback();
  }
}

function* updateBanner(action) {
  try {
    const response = yield updateBannerAPI(action.body);

    yield put(actions.updateBannerSuccess(response));
  } catch (e) {
    yield put(actions.updateBannerError(e));
  }
}

function* deleteBanner(action) {
  try {
    const response = yield deleteBannerAPI(action.body);
    console.log('response....', response);
    yield put(actions.deleteBannerSuccess(response));
  } catch (e) {
    console.log("Delete banner error");
    yield put(actions.deleteBannerError(e));
  }
}

export function* watchBanner() {
  yield takeLatest(ActionTypes.GET_LIST_BANNER_START, getListBanner);
  yield takeLatest(ActionTypes.CREATE_BANNER_START, createBanner);
  yield takeLatest(ActionTypes.UPDATE_BANNER_START, updateBanner);
  yield takeLatest(ActionTypes.DELETE_BANNER_START, deleteBanner);
}
