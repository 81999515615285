import moment from 'moment';
import React from 'react';
import { DATE_FORMAT } from '../../../common';
import { numberWithCommas } from '../../../utils';
export const columnUserReport = () => [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    align: 'center',
    width: '25%',
    ellipsis: true,
  },
  {
    title: 'Expire Time',
    dataIndex: 'time',
    key: 'time',
    align: 'center',
    width: '15%',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
    width: '15%',
  },
  {
    title: 'Name Of Sale',
    dataIndex: 'nameOfSale',
    key: 'nameOfSale',
    align: 'center',
    width: '20%',
  },
  {
    title: 'Active Status',
    dataIndex: 'activeStatus',
    key: 'activeStatus',
    align: 'center',
    width: '15%',
  },
];

export const columnSaleReport = () => [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    align: 'center',
    width: '25%',
    ellipsis: true,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
    width: '15%',
    render: (value, record) => {
      return (<p>{value ? `${numberWithCommas(value)} VND` : '--'}</p>)
    }
  },
  {
    title: 'Name Of Sale',
    dataIndex: 'nameOfSale',
    key: 'nameOfSale',
    align: 'center',
    width: '20%',
  },
  {
    title: 'Upgrade At',
    dataIndex: 'upgradedAt',
    key: 'upgradedAt',
    align: 'center',
    width: '15%',
    render: (value) => {
      return <p>{moment.unix(value).format(DATE_FORMAT.DDMMYY_HHMMSS)}</p>
    }
  },
  {
    title: 'Upgrade To',
    dataIndex: 'upgradedTo',
    key: 'upgradedTo',
    align: 'center',
    width: '15%',
    render: (value) => {
      return <p>{moment.unix(value).format(DATE_FORMAT.DDMMYY_HHMMSS)}</p>
    }
  },
];
