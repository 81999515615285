export const ActionTypes = {
  GET_LIST_SIGNAL_START: 'GET_LIST_SIGNAL_START',
  GET_LIST_SIGNAL_SUCCESS: 'GET_LIST_SIGNAL_SUCCESS',
  GET_LIST_SIGNAL_ERROR: 'GET_LIST_SIGNAL_ERROR',

  UPDATE_SIGNAL_START: 'UPDATE_SIGNAL_START',
  UPDATE_SIGNAL_SUCCESS: 'UPDATE_SIGNAL_SUCCESS',
  UPDATE_SIGNAL_ERROR: 'UPDATE_SIGNAL_ERROR',

  GET_LIST_STATUS_SIGNAL_START: 'GET_LIST_STATUS_SIGNAL_START',
  GET_LIST_STATUS_SIGNAL_SUCCESS: 'GET_LIST_STATUS_SIGNAL_SUCCESS',
  GET_LIST_STATUS_SIGNAL_ERROR: 'GET_LIST_STATUS_SIGNAL_ERROR',

  RESET_TYPE_OF_ACTION: 'RESET_TYPE_OF_ACTION',
};

export const actions = {
  getListSignalStart: (body) => ({
    type: ActionTypes.GET_LIST_SIGNAL_START,
    body,
  }),
  getListSignalSuccess: (payload) => ({
    type: ActionTypes.GET_LIST_SIGNAL_SUCCESS,
    payload,
  }),
  getListSignalError: (payload) => ({
    type: ActionTypes.GET_LIST_SIGNAL_ERROR,
    payload,
  }),

  updateSignalStart: (body) => ({
    type: ActionTypes.UPDATE_SIGNAL_START,
    body,
  }),
  updateSignalSuccess: (payload) => ({
    type: ActionTypes.UPDATE_SIGNAL_SUCCESS,
    payload,
  }),
  updateSignalError: (payload) => ({
    type: ActionTypes.UPDATE_SIGNAL_ERROR,
    payload,
  }),

  getListStatusSignalStart: (body) => ({
    type: ActionTypes.GET_LIST_STATUS_SIGNAL_START,
    body,
  }),
  getListStatusSignalSuccess: (payload) => ({
    type: ActionTypes.GET_LIST_STATUS_SIGNAL_SUCCESS,
    payload,
  }),
  getListStatusSignalError: (payload) => ({
    type: ActionTypes.GET_LIST_STATUS_SIGNAL_ERROR,
    payload,
  }),

  resetTypeOfAction: () => ({
    type: ActionTypes.RESET_TYPE_OF_ACTION,
  }),
};
