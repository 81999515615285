import { Button, Popconfirm } from 'antd';
import React from 'react';
import { BANNER_TYPE } from '../../common/constant';
const BANNER_BASE_URL = `${process.env.REACT_APP_API_URL}/banners/`

const convertBannerType = (type) => {
  const bannerType = BANNER_TYPE.filter(t => t.value === type)
  if (bannerType) {
    return bannerType[0].label
  }
}

const columns = (confirmDeleteBanner) => {
  return [
    {
      title: 'Created time',
      dataIndex: 'createdAt',
      width: '5%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '10%',
      align: 'center',
      editable: true,
    },
    {
      title: 'Image',
      dataIndex: 'image',
      width: '15%',
      render: (value) => <img style={{ height: '100px', width: '300px' }} src={BANNER_BASE_URL + value} alt={value} />,
      editable: true,
    },
    {
      title: 'Content',
      dataIndex: 'content',
      width: '10%',
      editable: true,
      render: (value) => value || '---',
    },
    {
      title: 'Link',
      dataIndex: 'link',
      width: '10%',
      editable: true,
      render: (value) => value || '---',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '5%',
      render: (value) => convertBannerType(value),
      editable: false,
    },
    {
      title: 'Action',
      dataIndex: 'bannerAction',
      width: '5%',
      align: 'center',
      render: (values, row) => {
        return (
          <Popconfirm
            title="Delete the banner"
            description="Are you sure to delete this banner?"
            onConfirm={() => confirmDeleteBanner(row)}
            onCancel={null}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>Delete</Button>
          </Popconfirm>
        )
      }
    },
  ];
};

export default columns;
