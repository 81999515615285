import { api } from '../services/api';

export const postChangePasswordAPI = (body) => {
  return api.post(`/changePassword`, body);
};

export const postLoginAPI = (params) => {
  return api.post('auth/admin/signin', params);
};

export const postLogoutAPI = () => {
  return api.get('/logout');
};

export const postConfirmEmailAPI = (body) => {
  return api.post('/resetPassword', body);
};

export const postResetPasswordAPI = (body) => {
  return api.post('/confirmResetPassword', body);
};
