import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import { getListPairsAPI, updatePairStatusAPI } from '../../services/PairsRequest';

function* getListPairs(action) {
  try {
    const response = yield getListPairsAPI(action.body);
    yield put(actions.getListPairsSuccess(response));
  } catch (e) {
    yield put(actions.getListPairsError(e));
  }
}

function* updatePairStatus(action) {
  try {
    const response = yield updatePairStatusAPI(action.body);
    if (response.statusCode === 0) {
      yield put(actions.updatePairStatusSuccess(response.data));
    }
  } catch (e) {
    yield put(actions.updatePairStatusError(e));
  }
}

export function* watchPairs() {
  yield takeLatest(ActionTypes.GET_LIST_PAIRS_START, getListPairs);
  yield takeLatest(ActionTypes.UPDATE_PAIR_STATUS_START, updatePairStatus);
}
