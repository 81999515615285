export const ActionTypes = {
  SHOW_LOADING: 'SHOW_LOADING',
  HIDE_LOADING: 'HIDE_LOADING',
  RESET_PARAM_SEARCH: 'RESET_PARAM_SEARCH',
};

export const actions = {
  showLoading: () => ({
    type: ActionTypes.SHOW_LOADING,
  }),
  hideLoading: () => ({
    type: ActionTypes.HIDE_LOADING,
  }),
  resetParamSearch: () => ({
    type: ActionTypes.RESET_PARAM_SEARCH,
  }),
};
