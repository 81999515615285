import React from 'react';
import { Radio, Select } from 'antd';
import { numberWithCommas } from '../../utils';

const { Option } = Select;

const columns = (onChangeStatus, listSignalsStatus) => {
  const options = [
    { value: false, label: 'Hide' },
    { value: true, label: 'Show' },
  ];

  return [
    {
      title: 'Created time',
      dataIndex: 'createdAt',
      width: '10%',
    },
    {
      title: 'Pairs',
      dataIndex: 'pairs',
      width: '10%',
      align: 'center',
    },
    {
      title: 'Open Price',
      dataIndex: 'openPrice',
      width: '10%',
      render: (value) => numberWithCommas(value),
    },
    {
      title: 'Take Profit',
      dataIndex: 'takeProfit',
      width: '10%',
      render: (value) => value || '---',
    },
    {
      title: 'Stoploss',
      dataIndex: 'stopLoss',
      width: '10%',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '10%',
      align: 'center',
    },
    {
      title: 'Pips',
      dataIndex: 'pips',
      editable: true,
      width: '10%',
      render: (value, record) => (value ? `${value} ${record.pipsUnit}` : '---'),
    },
    {
      title: 'Closed time',
      dataIndex: 'closedTime',
      width: '10%',
      render: (value) => value || '---',
    },
    {
      title: 'Status',
      dataIndex: 'signalStatus',
      width: '10%',
      align: 'center',
      render: (value, record) => (
        <Select
          value={value}
          onChange={(value) => onChangeStatus(record)({ signalStatus: value })}
          style={{ width: '100%' }}
        >
          {listSignalsStatus?.map((item) => (
            <Option key={item}>{item}</Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'signalStatus',
      width: '10%',
      align: 'center',
      render: (value, record) =>
        value === 'Closed' && (
          <Radio.Group
            options={options}
            onChange={(event) => onChangeStatus(record)({ isShow: event.target.value })}
            value={record.isShow}
            optionType="button"
          />
        ),
    },
  ];
};

export default columns;
