import { api } from '../services/api';

export const getListReportSaleAPI = (body) => {
  return api.get(`/admin/report-sale`, body);
};

export const getListReportUserAPI = (body) => {
  return api.get(`/admin/report-expire`, body);
};

export const getExportReportSaleAPI = (body) => {
  return api.get(`/admin/export-report-sale`, body);
};

export const getExportReportUserAPI = (body) => {
  return api.get(`/admin/export-report-expire`, body);
};