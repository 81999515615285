export const ActionTypes = {
  BAN_USER_START: 'BAN_USER_START',
  BAN_USER_SUCCESS: 'BAN_USER_SUCCESS',
  BAN_USER_ERROR: 'BAN_USER_ERROR',

  RESET_TYPE_OF_ACTION: 'RESET_TYPE_OF_ACTION',
};

export const actions = {
  banUserStart: (body) => ({
    type: ActionTypes.BAN_USER_START,
    body,
  }),
  banUserSuccess: (payload) => ({
    type: ActionTypes.BAN_USER_SUCCESS,
    payload,
  }),
  banUserError: (payload) => ({
    type: ActionTypes.BAN_USER_ERROR,
    payload,
  }),

  resetTypeOfAction: () => ({
    type: ActionTypes.RESET_TYPE_OF_ACTION,
  }),
};
