import { ActionTypes } from './actions';

const DEFAULT_STATE = {
  reportSale: {
    listUser: [],
    totalAmount: 0,
    totalRecords: 0,
  },
  reportUser: {
    content: [],
    totalElements: 0,
  },
  listPackage: [],
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_LIST_REPORT_SALE_SUCCESS:
      return {
        ...state,
        reportSale: action.payload,
      };
    case ActionTypes.GET_LIST_REPORT_SALE_ERROR:
      return {
        ...state,
        reportSale: action.payload,
      };

    case ActionTypes.GET_LIST_REPORT_USER_SUCCESS:
      return {
        ...state,
        reportUser: action.payload,
      };
    case ActionTypes.GET_LIST_REPORT_USER_ERROR:
      return {
        ...state,
        reportUser: action.payload,
      };
    default:
      return state;
  }
};
