import IconTransaction from '../../resources/images/menu_icon/icon_transaction.png';
import IconProfit from '../../resources/images/menu_icon/icon_profit.png';
import IconUser from '../../resources/images/menu_icon/icon_user.png';
import IconReport from '../../resources/images/menu_icon/icon_report.svg';
import IconConfigCommon from '../../resources/images/menu_icon/icon_setting.png';

export const MenuData = () => [
  {
    path: '/',
    name: 'Pairs',
    icon: IconTransaction,
  },
  {
    path: '/signal',
    name: 'Signals',
    icon: IconProfit,
  },
  {
    path: '/users',
    name: 'Users',
    icon: IconUser,
  },
  {
    path: '/reports',
    name: 'Reports',
    icon: IconReport,
  },
  {
    path: '/banners',
    name: 'Banners',
    icon: IconReport,
  },
  {
    path: '/telegram',
    name: 'Telegram',
    icon: IconUser,
  },
  {
    path: '/config',
    name: 'Config',
    icon: IconConfigCommon,
  }
];
