import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import { getListSignalAPI, updateSignalAPI, getListStatusSignalAPI } from '../../services/SignalRequest';

function* getListSignal(action) {
  try {
    const response = yield getListSignalAPI(action.body);
    yield put(actions.getListSignalSuccess(response));
  } catch (e) {
    yield put(actions.getListSignalError(e));
  }
}

function* updateSignal(action) {
  try {
    const response = yield updateSignalAPI(action.body);

    yield put(actions.updateSignalSuccess(response));
  } catch (e) {
    yield put(actions.updateSignalError(e));
  }
}

function* getListStatusSignal(action) {
  try {
    const response = yield getListStatusSignalAPI(action.body);
    yield put(actions.getListStatusSignalSuccess(response));
  } catch (e) {
    yield put(actions.getListStatusSignalError(e));
  }
}

export function* watchSignal() {
  yield takeLatest(ActionTypes.GET_LIST_SIGNAL_START, getListSignal);
  yield takeLatest(ActionTypes.UPDATE_SIGNAL_START, updateSignal);
  yield takeLatest(ActionTypes.GET_LIST_STATUS_SIGNAL_START, getListStatusSignal);
}
