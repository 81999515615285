import { message } from 'antd';
import { getI18n } from 'react-i18next';

export default function showMessage(msgType, msgContent, objValue) {
  message[msgType]({
    content: getI18n().t(msgContent, objValue),
    key: 'mes',
    className: 'event-message',
    duration: 3,
    style: {
      marginTop: '30px',
    },
  });
}
