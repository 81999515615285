export const ActionTypes = {
  GET_LIST_BANNER_START: 'GET_LIST_BANNER_START',
  GET_LIST_BANNER_SUCCESS: 'GET_LIST_BANNER_SUCCESS',
  GET_LIST_BANNER_ERROR: 'GET_LIST_BANNER_ERROR',

  CREATE_BANNER_START: 'CREATE_BANNER_START',
  CREATE_BANNER_SUCCESS: 'CREATE_BANNER_SUCCESS',
  CREATE_BANNER_ERROR: 'CREATE_BANNER_ERROR',

  UPDATE_BANNER_START: 'UPDATE_BANNER_START',
  UPDATE_BANNER_SUCCESS: 'UPDATE_BANNER_SUCCESS',
  UPDATE_BANNER_ERROR: 'UPDATE_BANNER_ERROR',

  DELETE_BANNER_START: 'DELETE_BANNER_START',
  DELETE_BANNER_SUCCESS: 'DELETE_BANNER_SUCCESS',
  DELETE_BANNER_ERROR: 'DELETE_BANNER_ERROR',

  RESET_TYPE_OF_ACTION: 'RESET_TYPE_OF_ACTION',
};

export const actions = {
  getListBannerStart: (body) => ({
    type: ActionTypes.GET_LIST_BANNER_START,
    body,
  }),
  getListBannerSuccess: (payload) => ({
    type: ActionTypes.GET_LIST_BANNER_SUCCESS,
    payload,
  }),
  getListBannerError: (payload) => ({
    type: ActionTypes.GET_LIST_BANNER_ERROR,
    payload,
  }),

  createBannerStart: (body, callback) => ({
    type: ActionTypes.CREATE_BANNER_START,
    body,
    callback
  }),
  createBannerSuccess: (payload) => ({
    type: ActionTypes.CREATE_BANNER_SUCCESS,
    payload,
  }),
  createBannerError: (payload) => ({
    type: ActionTypes.CREATE_BANNER_ERROR,
    payload,
  }),

  updateBannerStart: (body) => ({
    type: ActionTypes.UPDATE_BANNER_START,
    body,
  }),
  updateBannerSuccess: (payload) => ({
    type: ActionTypes.UPDATE_BANNER_SUCCESS,
    payload,
  }),
  updateBannerError: (payload) => ({
    type: ActionTypes.UPDATE_BANNER_ERROR,
    payload,
  }),

  deleteBannerStart: (body) => ({
    type: ActionTypes.DELETE_BANNER_START,
    body,
  }),
  deleteBannerSuccess: (payload) => ({
    type: ActionTypes.DELETE_BANNER_SUCCESS,
    payload,
  }),
  deleteBannerError: (payload) => ({
    type: ActionTypes.DELETE_BANNER_ERROR,
    payload,
  }),

  resetTypeOfAction: () => ({
    type: ActionTypes.RESET_TYPE_OF_ACTION,
  }),
};
