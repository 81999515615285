import React from 'react';
import { numberWithCommas } from '../../utils';

const columns = (onChangePairStatus) => {
  return [
    {
      title: 'Pairs',
      dataIndex: 'pairs',
      key: 'pairs',
      width: '20%',
      align: 'center',
    },
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      align: 'center',
      width: '20%',
      render: (value) => <img className="pairs-icon" src={value} alt={value} />,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: '20%',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      width: '20%',
      render: (value) => numberWithCommas(value),
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   key: 'status',
    //   width: '20%',
    //   align: 'center',
    //   render: (value, record) => (
    //     <Radio.Group options={options} onChange={onChangePairStatus(record.key)} value={value} optionType="button" />
    //   ),
    // },
  ];
};

export default columns;
