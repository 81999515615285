import React, { useEffect, useState } from 'react';
import { Button, Divider, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './actions';
import { ActionTypes } from './actions';
import showMessage from '../../components/Messsage';
import { TYPE_OF_ANT_DESIGN } from '../../common';
import { trim } from 'lodash-es';


function ConfigCommonPage() {
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [sliderTime, setSliderTime] = useState('');

  const winrateReal = useSelector((state) => state.ConfigCommonReducer.config.winrate);
  const winrateAddtional = useSelector((state) => state.ConfigCommonReducer.config.winrateAddition);
  const sliderTimeConfig = useSelector((state) => state.ConfigCommonReducer.config.sliderTime);
  const rateFake = (winrateAddtional + winrateReal).toFixed(2)
  const actionType = useSelector((state) => state.ConfigCommonReducer.type);

  useEffect(() => {
    setSliderTime(sliderTimeConfig)
  }, [sliderTimeConfig]);

  const onUpdateConfig = (event, input, key) => {
    const { value } = event.target;
    dispatch(actions.updateConfigStart({key, value: trim(value || input)}));
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const handleChangeSliderTime = (event) => {
    const { value } = event.target;
    setSliderTime(value);
  };

  useEffect(() => {
    dispatch(actions.getConfigStart());
  }, [dispatch]);

  useEffect(() => {
    if (actionType === ActionTypes.UPDATE_CONFIG_SUCCESS) {
      showMessage(TYPE_OF_ANT_DESIGN.SUCCESS, 'Update Winrate Successfully!');
      dispatch(actions.resetTypeOfAction());
      dispatch(actions.getConfigStart());
    }
  }, [dispatch, actionType]);

  return (
    <div className="signal">
      <Divider orientation="left" orientationMargin="0">Winrate</Divider>
      <div>Winrate Real: {winrateReal}</div>
      <div>Winrate Affter: {rateFake}</div>
      <div className="search-panel">
        <div className='add-rate'>Add rates: </div>
        <Input allowClear onPressEnter={(e) => onUpdateConfig(e, searchValue, "WINRATE")} onChange={handleSearchChange} value={searchValue} />
        <Button onClick={(e) => onUpdateConfig(e, searchValue, "WINRATE")}>Update</Button>
      </div>
      <Divider orientation="left" orientationMargin="0">Banner Slider Time</Divider>
      <div className="search-panel">
        <div className='add-rate'>Slider Time: </div>
        <Input allowClear onPressEnter={(e) => onUpdateConfig(e, sliderTime, "SLIDER_TIME")} onChange={handleChangeSliderTime} value={sliderTime} />
        <Button onClick={(e) => onUpdateConfig(e, sliderTime, "SLIDER_TIME")}>Update</Button>
      </div>
    </div>
  );
}

export default ConfigCommonPage;
