import React, { Fragment, useEffect, useState } from 'react';
import { Input } from 'antd';
import { ICON } from '../../common';

function InputComponent({
  field,
  form: { touched, errors },
  wrapperClass,
  labelTitle,
  required,
  inputLength,
  type,
  icon,
  iconSearch,
  errorTxt,
  visible,
  callbackClickIcon,
  isInLine,
  isConvertRate,
  ...props
}) {
  const [typePassword, setTypePassword] = useState('password');
  useEffect(() => {
    setTypePassword('password');
  }, [visible]);

  const toggleIconEye = () => {
    setTypePassword(typePassword === 'password' ? 'text' : 'password');
  };

  const renderIcon = () => {
    return (
      <span
        className={`icon ${typePassword === 'text' ? 'active' : ''}`}
        onClick={icon ? callbackClickIcon : toggleIconEye}
      >
        {icon ? icon : type === 'password' ? (typePassword === 'password' ? ICON.EYE_OPEN : ICON.EYE_CLOSE) : null}
        {iconSearch && ICON.SEARCH}
      </span>
    );
  };

  return (
    <div className={`input ${wrapperClass}`}>
      {!isInLine && labelTitle ? (
        <label htmlFor={field.name}>
          {labelTitle} {required && <span className="required">*</span>}
        </label>
      ) : (
        <div className="inputInLine">
          <label htmlFor={field.name}>
            {labelTitle} {required && <span className="required">*</span>}
          </label>
        </div>
      )}

      <Fragment>
        {!isInLine ? (
          <Input
            type={type === 'password' ? typePassword : type}
            maxLength={inputLength}
            id={field.name}
            {...field}
            {...props}
          />
        ) : isConvertRate ? (
          <div className={`rt-input-input-${field.name}`}>
            <div className="title-convert">
              <div className="inline-text">{isConvertRate === 'orangeRate' ? '1 Orange' : '1 Yen'}</div>
              <div className="text-inline">-</div>
            </div>
            <div className="content-convert">
              <input
                type={type === 'password' ? typePassword : type}
                maxLength={inputLength}
                id={field.name}
                {...field}
                {...props}
              />
            </div>
          </div>
        ) : (
          <div className={`rt-input-input-${field.name}`}>
            <input
              type={type === 'password' ? typePassword : type}
              maxLength={inputLength}
              id={field.name}
              {...field}
              {...props}
            />
          </div>
        )}
      </Fragment>
      {(type === 'password' || icon || iconSearch) && renderIcon()}
      {(errors[field.name] || errorTxt) && touched[field.name] && (
        <span className="error">
          {errors[field.name]}
          {errorTxt}
        </span>
      )}
    </div>
  );
}

export default InputComponent;
