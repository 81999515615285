import { Layout } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import HeaderCommon from '../../../components/Header';
import SiderCommon from '../../../components/Sider';
import { privateRoutes } from '../../../Routes';

const { Content } = Layout;

function PrivateLayout() {
  const authToken = useSelector((state) => state.LoginReducer.adminInfo?.accessToken);

  if (!authToken) {
    return <Redirect to="/login" />;
  }

  return (
    <Layout className="layout layout-private">
      <SiderCommon />
      <Layout>
        <HeaderCommon />
        <Content>
          <Switch>
            {privateRoutes.map((route, index) => {
              return <Route path={route.path} component={route.component} exact={route.exact} key={index} {...route} />;
            })}
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
}
export default withRouter(PrivateLayout);
