import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import { getListReportSaleAPI, getListReportUserAPI } from '../../services/ReportRequest';

function* getListReportSale(action) {
  const payload = action.params;
  const convertParams = {
    ...payload,
    from: payload.from ? payload.from.format('YYYY-MM-DD')?.toString() : null,
    to: payload.to ? payload.to.format('YYYY-MM-DD')?.toString() : null
  }
  try {
    const response = yield getListReportSaleAPI(convertParams);
    if (response.statusCode === 0) {
      yield put(actions.getListReportSaleSuccess(response.data));
    } else {
      yield put(actions.getListReportSaleError({}));
    }
  } catch (e) {
    yield put(actions.getListReportSaleError(e));
  }
}

function* getListReportUser(action) {
  try {
    const response = yield getListReportUserAPI(action.params);
    if (response.statusCode === 0) {
      yield put(actions.getListReportUserSuccess(response.data));
    } else {
      yield put(actions.getListReportUserError({}));
    }
  } catch (e) {
    yield put(actions.getListReportUserError(e));
  }
}

export function* watchReportSaga() {
  yield takeLatest(ActionTypes.GET_LIST_REPORT_SALE_START, getListReportSale);
  yield takeLatest(ActionTypes.GET_LIST_REPORT_USER_START, getListReportUser);
}
