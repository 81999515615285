import { api } from './api';

export const getListUserAPI = (params) => {
  return api.get('/admin/get-list-user', params);
};
export const getListPackageAPI = (params) => {
  return api.get('/admin/listPackage', params);
};
export const upgradeUserAPI = (params) => {
  return api.post(`/admin/upgradePlan`, params);
};

export const upgradeBundleUserAPI = (params) => {
  return api.post(`/admin/upgradeBatchPlan`, params);
};

export const updateUserAPI = (params) => {
  return api.post(`/admin/updateUser`, params);
};