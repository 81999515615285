export const ActionTypes = {
  GET_LIST_USERS_START: 'GET_LIST_USERS_START',
  GET_LIST_USERS_SUCCESS: 'GET_LIST_USERS_SUCCESS',
  GET_LIST_USERS_ERROR: 'GET_LIST_USERS_ERROR',

  GET_LIST_PACKAGE_START: 'GET_LIST_PACKAGE_START',
  GET_LIST_PACKAGE_SUCCESS: 'GET_LIST_PACKAGE_SUCCESS',
  GET_LIST_PACKAGE_ERROR: 'GET_LIST_PACKAGE_ERROR',

  UPGRADE_USER_START: 'UPGRADE_USER_START',
  UPGRADE_USER_SUCCESS: 'UPGRADE_USER_SUCCESS',
  UPGRADE_USER_ERROR: 'UPGRADE_USER_ERROR',

  UPGRADE_BUNDLE_USER_START: 'UPGRADE_BUNDLE_USER_START',
  UPGRADE_BUNDLE_USER_SUCCESS: 'UPGRADE_BUNDLE_USER_SUCCESS',
  UPGRADE_BUNDLE_USER_ERROR: 'UPGRADE_BUNDLE_USER_ERROR',

  UPDATE_USER_START: 'UPDATE_USER_START',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',

  RESET_TYPE_OF_ACTION: 'RESET_TYPE_OF_ACTION',
};

export const actions = {
  getListUserStart: (params) => ({
    type: ActionTypes.GET_LIST_USERS_START,
    params,
  }),
  getListUserSuccess: (payload) => ({
    type: ActionTypes.GET_LIST_USERS_SUCCESS,
    payload,
  }),
  getListUserError: (payload) => ({
    type: ActionTypes.GET_LIST_USERS_ERROR,
    payload,
  }),

  getListPackageStart: (params) => ({
    type: ActionTypes.GET_LIST_PACKAGE_START,
    params,
  }),
  getListPackageSuccess: (success) => ({
    type: ActionTypes.GET_LIST_PACKAGE_SUCCESS,
    payload: success,
  }),
  getListPackageError: (error) => ({
    type: ActionTypes.GET_LIST_PACKAGE_ERROR,
    payload: error,
  }),
  updateUserStart: (body) => ({
    type: ActionTypes.UPDATE_USER_START,
    body,
  }),
  updateUserSuccess: (payload) => ({
    type: ActionTypes.UPDATE_USER_SUCCESS,
    payload,
  }),
  updateUserError: (payload) => ({
    type: ActionTypes.UPDATE_USER_ERROR,
    payload,
  }),

  upgradeUserStart: (params, paramSearch, callback, callbackSuccess) => ({
    type: ActionTypes.UPGRADE_USER_START,
    params,
    paramSearch,
    callback,
    callbackSuccess,
  }),
  upgradeUserSuccess: (success) => ({
    type: ActionTypes.UPGRADE_USER_SUCCESS,
    payload: success,
  }),
  upgradeUserError: (error) => ({
    type: ActionTypes.UPGRADE_USER_ERROR,
    payload: error,
  }),

  upgradeBundleUserStart: (params, callback) => ({
    type: ActionTypes.UPGRADE_BUNDLE_USER_START,
    params,
    callback,
  }),
  upgradeBundleUserSuccess: (success) => ({
    type: ActionTypes.UPGRADE_BUNDLE_USER_SUCCESS,
    payload: success,
  }),
  upgradeBundleUserError: (error) => ({
    type: ActionTypes.UPGRADE_BUNDLE_USER_ERROR,
    payload: error,
  }),
  resetTypeOfAction: () => ({
    type: ActionTypes.RESET_TYPE_OF_ACTION,
  }),
};
