import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import { onRedirect, useWindowSize } from '../../utils';
import LogoSider from '../../resources/images/logo.png';
import { MenuData } from './menuData';

const { Sider } = Layout;

function SliderCommon() {
  const { t } = useTranslation();
  const location = useLocation();
  const { width } = useWindowSize();
  const isMobile = width <= 576;

  const renderMenu = () => {
    return MenuData(t).map((item, index) => {
      return (
        <li
          onClick={() => onRedirect(item.path)}
          key={index}
          className={`menu-item ${location.pathname === item.path ? 'active' : ''}`}
        >
          <img src={item.icon} alt="menu" />
          {!isMobile && <span className="name">{item.name}</span>}
        </li>
      );
    });
  };

  return (
    <Sider className={`layout__sider ${isMobile && 'mw-67px fl-67px'}`}>
      <div className="logo-wrapper">
        <img src={LogoSider} alt="logo silder" />
      </div>
      <div className="menu-wrapper">
        <ul className="menu-content">{renderMenu()}</ul>
      </div>
    </Sider>
  );
}

export default SliderCommon;
