import { ActionTypes } from './actions';

const DEFAULT_STATE = {
  adminInfo: {},
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.POST_LOGIN_SUCCESS:
      return {
        ...state,
        adminInfo: action.payload,
      };
      case ActionTypes.POST_LOGOUT:
        return {
          ...state,
          adminInfo: DEFAULT_STATE.adminInfo,
        };
    default:
      return state;
  }
};
