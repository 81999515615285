import React, { useEffect, useMemo, useState } from 'react';
import { Button, Input, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import EditableRow from './EditableRow';
import EditableCell from './EditableCell';
import { actions } from './actions';
import { DEFAULT_INITIAL_PAGE, DEFAULT_PAGE_SIZE, PIPS_UNIT } from '../../common/constant';
import { ActionTypes } from './actions';
import showMessage from '../../components/Messsage';
import { TYPE_OF_ANT_DESIGN, DATE_FORMAT } from '../../common';
import columns from './columns';
import { includes, trim } from 'lodash-es';
import moment from 'moment';

function SignalPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [searchValue, setSearchValue] = useState('');

  const signals = useSelector((state) => state.SignalReducer.signals);
  const updateSignalsStatus = useSelector((state) => state.SignalReducer.type);
  const listSignalsStatus = useSelector((state) => state.SignalReducer.status);

  const queryParams = useMemo(() => {
    const params = queryString.parse(location.search);
    setSearchValue(params?.text);

    return {
      ...params,
      text: params?.text || '',
      size: Number.parseInt(params?.size) || DEFAULT_PAGE_SIZE,
      page: Number.parseInt(params?.page) || DEFAULT_INITIAL_PAGE,
    };
  }, [location.search]);

  const onChangeStatus =
    (record) =>
    ({ isShow, signalStatus }) => {
      console.log('isShow..', isShow, record.isShow);
      dispatch(
        actions.updateSignalStart({
          id: record.key,
          isShow,
          pips: `${record.pips} ${PIPS_UNIT}`,
          signalStatus: signalStatus || record.signalStatus,
        })
      );
    };

  const dataSource = signals?.content?.map((signal) => {
    const { pips } = signal;
    const pipsValue = includes(pips, PIPS_UNIT) ? pips?.split(' ')[0] : pips;
    const pipsUnit = includes(pips, PIPS_UNIT) ? pips?.split(' ')[1] : '';

    return {
      key: signal?.id,
      createdAt: moment(signal?.createdAt).format(DATE_FORMAT.DDMMYY_HHMMSS),
      pairs: signal?.pairs,
      openPrice: signal?.openPrice,
      takeProfit: signal?.takeProfit,
      stopLoss: signal?.stopLoss,
      type: signal?.type,
      pips: pipsValue || pips,
      pipsUnit: pipsUnit || '',
      closedTime: signal?.closeTime ? moment(new Date(signal?.closeTime*1000)).format(DATE_FORMAT.DDMMYY_HHMMSS) : null,
      isShow: signal?.isShow,
      signalStatus: signal?.signalStatus,
    };
  });

  const onSearch = (event) => {
    const { value } = event.target;

    history.push({
      pathname: history.location.pathname,
      search: queryString.stringify({ ...queryParams, text: trim(value || searchValue) }),
    });
    dispatch(actions.getListSignalStart(queryParams));
  };

  const checkValueValid = (value) => {
    return value !== '' && value !== null && value !== undefined;
  };

  const handleSave = (data) => {
    const { key, pips, isShow } = data;

    dispatch(
      actions.updateSignalStart({
        id: key,
        pips: checkValueValid(pips) ? `${pips > 0 ? `+${pips}` : pips} ${PIPS_UNIT}` : '',
        isShow,
      })
    );
  };

  const handlePageChange = (page) => {
    history.push({
      pathname: history.location.pathname,
      search: queryString.stringify({ ...queryParams, page: page - 1 }),
    });
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const newColumns = columns(onChangeStatus, listSignalsStatus).map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  const handleSearchChange = (event) => {
    const { value } = event.target;

    setSearchValue(value);
  };

  useEffect(() => {
    dispatch(actions.getListStatusSignalStart());
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.getListSignalStart(queryParams));
  }, [dispatch, queryParams]);

  useEffect(() => {
    if (updateSignalsStatus === ActionTypes.UPDATE_SIGNAL_SUCCESS) {
      showMessage(TYPE_OF_ANT_DESIGN.SUCCESS, 'Update Signals Successfully!');
      dispatch(actions.resetTypeOfAction());
      dispatch(actions.getListSignalStart(queryParams));
    }
  }, [dispatch, queryParams, updateSignalsStatus]);

  return (
    <div className="signal">
      <div className="search-panel">
        <Input allowClear onPressEnter={onSearch} onChange={handleSearchChange} value={searchValue} />
        <Button onClick={onSearch}>Search</Button>
      </div>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={newColumns}
        className="table"
        pagination={{
          current: queryParams.page + 1,
          pageSize: queryParams.size,
          total: signals?.totalElements || 0,
          onChange: handlePageChange,
        }}
        scroll={{x:1024}}
      />
    </div>
  );
}

export default SignalPage;
