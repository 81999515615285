export const ActionTypes = {
  POST_LOGIN_START: "POST_LOGIN_START",
  POST_LOGIN_SUCCESS: "POST_LOGIN_SUCCESS",
  POST_LOGIN_ERROR: "POST_LOGIN_ERROR",
  POST_LOGOUT: "POST_LOGOUT",
  POST_LOGOUT_SUCCESS: "POST_LOGOUT_SUCCESS",
  POST_LOGOUT_ERROR: "POST_LOGOUT_ERROR",
  POST_CHANGE_PASSWORD_START: "POST_CHANGE_PASSWORD_START",
  POST_CHANGE_PASSWORD_SUCCESS: "POST_CHANGE_PASSWORD_SUCCESS",
  POST_CHANGE_PASSWORD_ERROR: "POST_CHANGE_PASSWORD_ERROR",
  RESET_MESSAGE: "RESET_MESSAGE",
  POST_CONFIRM_EMAIL_START: "POST_CONFIRM_EMAIL_START",
  POST_CONFIRM_EMAIL_SUCCESS: "POST_CONFIRM_EMAIL_SUCCESS",
  POST_CONFIRM_EMAIL_ERROR: "POST_CONFIRM_EMAIL_ERROR",
  RESET_FROM_FORGOT: "RESET_FROM_FORGOT",
  POST_RESET_PASSWROD_START: "POST_RESET_PASSWROD_START",
  POST_RESET_PASSWROD_SUCCESS: "POST_RESET_PASSWROD_SUCCESS",
  POST_RESET_PASSWROD_ERROR: "POST_RESET_PASSWROD_ERROR",
};

export const actions = {
  postResetPasswordStart: (body) => ({
    type: ActionTypes.POST_RESET_PASSWROD_START,
    body,
  }),
  postResetPasswordSuccess: (success) => ({
    type: ActionTypes.POST_RESET_PASSWROD_SUCCESS,
    payload: success,
  }),
  postResetPasswordError: (error) => ({
    type: ActionTypes.POST_RESET_PASSWROD_ERROR,
    payload: error,
  }),
  resetFormForgotStart: () => ({
    type: ActionTypes.RESET_FROM_FORGOT,
  }),
  postConfirmEmailStart: (body) => ({
    type: ActionTypes.POST_CONFIRM_EMAIL_START,
    body,
  }),
  postConfirmEmailSuccess: (success) => ({
    type: ActionTypes.POST_CONFIRM_EMAIL_SUCCESS,
    payload: success,
  }),
  postConfirmEmailError: (error) => ({
    type: ActionTypes.POST_CONFIRM_EMAIL_ERROR,
    payload: error,
  }),
  postLoginStart: (body) => ({
    type: ActionTypes.POST_LOGIN_START,
    body,
  }),
  postLoginSuccess: (success) => ({
    type: ActionTypes.POST_LOGIN_SUCCESS,
    payload: success,
  }),
  postLoginError: (error) => ({
    type: ActionTypes.POST_LOGIN_ERROR,
    payload: error,
  }),
  resetMessage: () => ({
    type: ActionTypes.RESET_MESSAGE,
  }),
  postLogout: () => ({
    type: ActionTypes.POST_LOGOUT,
  }),
  postLogoutError: (error) => ({
    type: ActionTypes.POST_LOGOUT_ERROR,
    payload: error,
  }),
  postChangePasswordStart: (body) => ({
    type: ActionTypes.POST_CHANGE_PASSWORD_START,
    body,
  }),
  postChangePasswordSuccess: (success) => ({
    type: ActionTypes.POST_CHANGE_PASSWORD_SUCCESS,
    payload: success,
  }),
  postChangePasswordError: (error) => ({
    type: ActionTypes.POST_CHANGE_PASSWORD_ERROR,
    payload: error,
  }),
};
