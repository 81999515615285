import { api } from './api';

export const createBannerAPI = (body) => {
  return api.postMultipart('/admin/banners', body);
};

export const getListBannerAPI = (body) => {
  return api.get('/admin/banners', body);
};

export const updateBannerAPI = (body) => {
  return api.putMultipart('/admin/banners', body);
};

export const deleteBannerAPI = (body) => {
  return api.delete('/admin/banners/' + body.id, {});
};
