import React, { useEffect, useMemo, useState } from 'react';
import { Button, Table, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import columns from './columns';
import { actions, ActionTypes } from './actions';
import { DEFAULT_INITIAL_PAGE, DEFAULT_PAGE_SIZE } from '../../common/constant';
import showMessage from '../../components/Messsage';
import { TYPE_OF_ANT_DESIGN } from '../../common';
import { trim } from 'lodash-es';

function HomePage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [searchValue, setSearchValue] = useState('');

  const pairs = useSelector((state) => state.PairsReducer.pairs);
  const updatePairStatus = useSelector((state) => state.PairsReducer.type);

  const queryParams = useMemo(() => {
    const params = queryString.parse(location.search);
    setSearchValue(params?.text);

    return {
      ...params,
      size: Number.parseInt(params?.size) || DEFAULT_PAGE_SIZE,
      page: Number.parseInt(params?.page) || DEFAULT_INITIAL_PAGE,
    };
  }, [location.search]);

  const handleSubmit = (event) => {
    const { value } = event.target;

    history.push({
      pathname: history.location.pathname,
      search: queryString.stringify({ ...queryParams, text: trim(value || searchValue) }),
    });

    dispatch(actions.getListPairsStart(queryParams));
  };

  const onChangeStatus = (id) => (event) => {
    const { value } = event.target;
    dispatch(
      actions.updatePairStatusStart({
        id,
        status: value,
      })
    );
  };

  const dataSource = pairs?.content?.map((pair) => {
    return {
      key: pair.id,
      pairs: pair.pairs,
      icon: pair.icon,
      type: pair.type,
      price: pair.price,
      status: pair.status,
    };
  });

  const handlePageChange = (page) => {
    history.push({
      pathname: history.location.pathname,
      search: queryString.stringify({ ...queryParams, page: page - 1 }),
    });
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;

    setSearchValue(value);
  };

  useEffect(() => {
    dispatch(actions.getListPairsStart(queryParams));
  }, [dispatch, queryParams]);

  useEffect(() => {
    if (updatePairStatus === ActionTypes.UPDATE_PAIR_STATUS_SUCCESS) {
      showMessage(TYPE_OF_ANT_DESIGN.SUCCESS, 'Update status successfully!');
      dispatch(actions.getListPairsStart(queryParams));
      dispatch(actions.resetTypeOfAction());
    }
  }, [dispatch, queryParams, updatePairStatus]);

  return (
    <div className="pairs">
      <div className="search-panel">
        <Input allowClear onPressEnter={handleSubmit} onChange={handleSearchChange} value={searchValue} />
        <Button onClick={handleSubmit}>Search</Button>
      </div>
      <Table
        bordered
        dataSource={dataSource}
        columns={columns(onChangeStatus)}
        className="table"
        pagination={{
          current: queryParams.page + 1,
          pageSize: queryParams.size,
          total: pairs?.totalElements || 0,
          onChange: handlePageChange,
        }}
        scroll={{x:1024}}
      />
    </div>
  );
}
export default HomePage;
