import { ActionTypes } from './actions';

const DEFAULT_STATE = {
  config: [],
  type: '',
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.BAN_USER_SUCCESS:
      return {
        ...state,
        config: action.payload.data,
        type: action.type
      };
    case ActionTypes.BAN_USER_ERROR:
      return {
        ...state,
        config: action.payload,
        type: action.type
      };

    case ActionTypes.RESET_TYPE_OF_ACTION:
      return {
        ...state,
        type: '',
      };
    default:
      return state;
  }
};
