import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import Input from '../../components/Input';
import ButtonCommon from '../../components/Button';
import { Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../Login/actions';
import { onRedirect } from '../../utils';
import { getToken } from '../../services/api';
import { validateLogin } from '../../utils/validateStatus';

function Login() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialValues = { email: '', password: '' };
  const authToken = useSelector((state) => state.LoginReducer.adminInfo?.accessToken);

  const onsubmitLogin = (values) => {
    const body = {
      usernameOrEmail: values.email,
      password: values.password,
    };
    dispatch(actions.postLoginStart(body));
  };

  useEffect(() => {
    if (authToken) {
      getToken(authToken);
      onRedirect('/');
    }
  }, [authToken]);

  return (
    <Row className="login">
      <div className="login_container">
        <Row className="login_content-wrapper">
          <Col xs={24}>
            <div className="login-card">
              <h1>{t('login.title_welcome')}</h1>
              <div className="title-login">{t('login.btnLoginTxt').toUpperCase()}</div>
              <Formik validationSchema={validateLogin(t)} initialValues={initialValues} onSubmit={onsubmitLogin}>
                {({ values, handleSubmit }) => (
                  <div className="login-form">
                    <Field
                      name="email"
                      placeholder={t('login.email')}
                      value={values?.email?.trim()}
                      inputLength={100}
                      component={Input}
                    />
                    <Field
                      name="password"
                      type="password"
                      placeholder={t('login.password')}
                      inputLength={20}
                      component={Input}
                    />
                    <div className="button-login">
                      <ButtonCommon onClick={handleSubmit} className="btn-default" title={t('login.btnLoginTxt')} />
                    </div>
                  </div>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </div>
    </Row>
  );
}
export default Login;
