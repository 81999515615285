export const FORMAT_DATE = 'DD/MM/YYYY';
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_INITIAL_PAGE = 0;
export const PIPS_UNIT = 'PIPS';
export const NUMBER_REGEX = /^[0-9]*$/;
export const BANNER_TYPE = [
    {
      label: "In App",
      value: "INTERNAL"
    },
    {
      label: "Web App",
      value: "WEB_APP"
    }
  ]
