import React from "react";
import { Button } from "antd";

function ButtonCommon({ title, ...props }) {
  return (
    <div className="button">
      <Button type="primary" title={title} {...props}>
        {title}
      </Button>
    </div>
  );
}

export default ButtonCommon;
