import { ActionTypes } from './actions';

const DEFAULT_STATE = {
  pairs: {
    content: [],
    page: 0,
    size: 10,
    totalElements: 0,
  },
  type: '',
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_LIST_PAIRS_SUCCESS:
      return {
        ...state,
        pairs: action.payload,
      };
    case ActionTypes.GET_LIST_PAIRS_ERROR:
      return {
        ...state,
        pairs: action.payload,
      };
    case ActionTypes.UPDATE_PAIR_STATUS_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.UPDATE_PAIR_STATUS_ERROR:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.RESET_TYPE_OF_ACTION:
      return {
        ...state,
        type: '',
      };
    default:
      return state;
  }
};
