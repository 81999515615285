import React, { useEffect, useMemo, useState } from 'react';
import { Button, Table, Input, Select, DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { actions } from '.././actions';
import { DEFAULT_INITIAL_PAGE, DEFAULT_PAGE_SIZE } from '../../../common/constant';
import { DATE_FORMAT, REPORT_SALE_DURATION, REPORT_TAB } from '../../../common';
import { columnSaleReport } from './columns';
import ButtonCommon from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { getExportReportSaleAPI } from '../../../services/ReportRequest';
import { numberWithCommas } from '../../../utils';

const { Option } = Select;

const initParams = {
  type: null,
  from: null,
  to: null,
  size: DEFAULT_PAGE_SIZE,
  page: DEFAULT_INITIAL_PAGE
}

function ReportSale({ curTab }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const listUser = useSelector((state) => state.ReportReducer.reportSale);
  const [params, setParams] = useState(initParams);

  const onSearch = () => {
    dispatch(actions.getListReportSaleStart(params));
  };

  const handlePageChange = (page) => {
    dispatch(actions.getListReportSaleStart({ ...params, page: page - 1 }));
    setParams({ ...params, page: page - 1 });
  };

  const onChangeSelect = (value) => {
    setParams({ ...params, type: value, from: null, to: null });
  }

  const handleChangeDate = (field) => (date, dateString) => {
    setParams({ ...params, [field]: date });
  }

  useEffect(() => {
    setParams(initParams);
  }, [curTab])

  const onExport = async () => {
    try {
      const response = await getExportReportSaleAPI(params);
      console.log(response);
      const fileUrl = `${process.env.REACT_APP_API_URL}${response?.data}`
      const link = document.createElement('a');

      link.href = fileUrl;
      link.target = '_blank';
      link.click();
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (curTab === REPORT_TAB.REPORT_SALE) {
      dispatch(actions.getListReportSaleStart(params));
    }
  }, [location.search, curTab]);

  return (
    <div className="sale-tab">
      <div className="search-panel">
        <Select
          value={params.type}
          defaultValue="THIS_MONTH"
          onChange={(value) => onChangeSelect(value)}
          placeholder="Enter duration"
        >
          {REPORT_SALE_DURATION?.map((item, index) => (
            <Option key={index} value={item.value}>{item.label}</Option>
          ))}
        </Select>
        {params.type === 'CUSTOM_DATE' && (
          <>
            <DatePicker onChange={handleChangeDate('from')} value={params.from} placeholder="From" format={DATE_FORMAT.DDMMYY_SEARCH} showToday={false} />
            <DatePicker onChange={handleChangeDate('to')} value={params.to} placeholder="To" format={DATE_FORMAT.DDMMYY_SEARCH} showToday={false} />
          </>
        )}
        <Button onClick={onSearch}>Search</Button>
        {listUser.totalRecords > 0 && <ButtonCommon onClick={onExport} className="btn-default orange" title="Export CSV" />}
      </div>
      <div className='total_tab'>
        <p>{t('total.total_email', { email: listUser?.totalRecords || 0 })}</p>
        <p>{t('total.total_amount', { amount: numberWithCommas(listUser?.totalAmount) || 0 })}</p>
      </div>
      <Table
        bordered
        dataSource={listUser.listUser}
        columns={columnSaleReport()}
        className="table"
        pagination={{
          current: params.page + 1,
          pageSize: params.size,
          total: listUser.totalRecords,
          onChange: handlePageChange,
        }}
        scroll={{x:1024}}
      />
    </div>
  );
}
export default ReportSale;
